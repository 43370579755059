<template>
    <section class="hero has-background-info">
      <div class="hero-body">
        <div class="container has-text-centered is-fluid">
          <div class="column is-4 is-offset-4">
            <h3 class="title has-text-white">Tarkistetaan kirjautumislinkki</h3>
            <div class="box">
                <p v-if="error === false">Odota hetki..</p><br>
                <b-progress v-if="error === false"></b-progress>
              <b-notification
                type="is-danger"
                aria-close-label="Sulje ilmoitus"
                v-if="error === true"
                role="alert"
              >
                Kirjautuminen ei onnistunut, pyydä uusi kirjautumislinkki
              </b-notification>
              <br />
            </div>
          </div>
        </div>
      </div>
      <aaltotausta />
    </section>
  </template>


<script>
import User from "@/models/user";
import Aaltotausta from "@/components/Aaltotausta.vue";

export default {
    components: {
    Aaltotausta,
  },
  name: "KirjautuminenGenerated",
    data() {
    return {
      user: new User("", ""),
      loading: false,
      puhelin: "",
      token: "",
      message: "",
      error: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profiili");
    } else {
        this.user.puhelin = this.$route.params.puhelin;
        this.user.token = this.$route.params.token;
        this.TokenKirjautuminenYllapitoGenerated()
    }
  },
  methods: {

    TokenKirjautuminenYllapitoGenerated() {
      this.loading = true;
      if (this.user.puhelin && this.user.token) {
        this.$store.dispatch("auth/logintokenAdminGenerated", this.user).then(
          () => {
            this.$router.push("/profiili");
          },
          (error) => {
            this.loading = false;
            this.error = true;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  }
}
</script>

<style>

</style>