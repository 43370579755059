<template>
  <section class="hero has-background-info">
  <lumisade v-if="lumisade"/>

  <div class="container has-text-centered is-fluid">
  <lahin-huolto-kohde class="mt-4" v-if="currentUser"/>
  <b-button type="has-background-white-ter is-medium" v-if="currentUser" tag="router-link" :to="{ path: '/qr-huolto' }" icon-left="qrcode" expanded></b-button><br>
  <b-collapse 
            class="card" 
            animation="slide" 
            aria-id="contentIdForA11y3"
            :open="false"
            >
   
                <div
                    class="card-header"
                    role="button"
                     slot="trigger" 
                     slot-scope="props"
                    >
                    <p class="card-header-title">
                        Suodattimet
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'angle-up' : 'angle-down'">
                        </b-icon>
                    </a>
                
            </div>
            <div class="card-content">
                <div class="content has-background-white" style="height:100%">
                    <tyyppisuodatus/><br>
                    <tilasuodatus/>
                </div>
            </div>
  </b-collapse>
  <br>
  <div class="columns is-centered">      
  </div>
  <kohdeluettelo/>
  </div>

  <aaltotausta/>
<keksi/>
  </section>
</template>

<script>

import Lumisade from '@/components/Lumisade.vue'
import LahinHuoltoKohde from '@/components/LahinHuoltoKohde.vue'
import Kohdeluettelo from '@/components/Kohdeluettelo.vue'
import Aaltotausta from '@/components/Aaltotausta.vue'
import Tyyppisuodatus from '@/components/Tyyppisuodatus.vue'
import Tilasuodatus from '@/components/Tilasuodatus.vue'
import Keksi from '@/components/CookieNappi.vue'

export default {
  name: 'Etusivu',
  components: {
    Kohdeluettelo,
    Aaltotausta,
    Tyyppisuodatus,
    Tilasuodatus,
    Lumisade,
    Keksi,
    LahinHuoltoKohde
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    lumisade: {
    get: function () {
        return this.$store.state.lumisade
    },
  },

  }, mounted() {
    console.log(process.env.ROOT_API)
  }
}

</script>