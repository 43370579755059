import http from "@/http-common";
import authHeader from './auth-header';
// Hyvä ohje https://www.bezkoder.com/vue-js-crud-app/
class KohdeDataService {
  haeKohteet() {
    return http.get("/kohteet");
  }

  haeKohteetJulkinen() {
    return http.get("/kohteet/julkinen");
  }

  haeKohteetKayttaja(tunnusid) {
    return http.get(`/kohteet/yllapito/kayttaja/${tunnusid}`, { headers: authHeader() });
  }

  haeKohdeNimi(id){
  return http.get(`/kohde/nimi/${id}`, { headers: authHeader() });
  }

  haeKohdeTiedote(id){
    return http.get(`/kohde/tiedote/${id}`, { headers: authHeader() });
  }

  tallennaKohdeTiedote(data) {
    return http.post(`/kohde/tiedote/paivita`, data, { headers: authHeader() } );
  }

  haeKohteetHuolto() {
    return http.get("/kohteet/huoltovalinta");
  }

  haeKohteetHuoltoOikeus(id) {
    return http.get(`/kohteet/huoltovalinta/kayttaja/${id}`, { headers: authHeader() });
  }

  tarkistaOnkoHuoltoOikeus(kohdeid,tunnusid) {
    return http.get(`/kohde/${kohdeid}/oikeus/huolto/${tunnusid}`, { headers: authHeader() });
  }

  tarkistaOnkoMuokkausOikeus(kohdeid,tunnusid) {
    return http.get(`/kohde/${kohdeid}/oikeus/muokkaus/${tunnusid}`, { headers: authHeader() });
  }

  haeKohteetYllapito(id) {
    return http.get(`/kohteet/kayttaja/${id}`, { headers: authHeader() });
  }

  haeKohteetOikeudet() {
    return http.get("/kohteet/oikeudet", { headers: authHeader() });
  }

  haeKohde(id) {
    return http.get(`/kohde/${id}`);
  }

  haeKohteenKuva(id) {
    return http.get(`/kuva/${id}`);
  }

  haeKohdeKuvat(id) {
    return http.get(`/kohde/kuvat/${id}`);
  }

  haeKohdeLoki(id) {
    return http.get(`/kohde/loki/${id}`, { headers: authHeader() });
  }

  kohteenKommentointi(id) {
    return http.get(`/kohde/kommentti/${id}`);
  }
  
  uusiKommentti(data) {
    return http.post(`/kohde/kommentti/uusi`, data );
  }

  kohteenHuoltomerkinta(data) {
     return http.post(`/kohde/huoltomerkinta`, data, { headers: authHeader() } );
   }

   kohteenHuoltomerkintaQR(data) {
    return http.post(`/kohde/huoltomerkinta/qr`, data, { headers: authHeader() } );
  }

  kohteenTilamuutos(data) {
    return http.post(`/kohde/tila`, data, { headers: authHeader() } );
  }

  useankohteenTilamuutos(data) {
    return http.post(`/kohteet/tila`, data, { headers: authHeader() } );
  }

  kohteenKaytostaPoisto(data) {
    return http.post(`/kohde/poistakaytosta`, data, { headers: authHeader() } );
  }

  kohteenPoisto(data) {
    return http.post(`/kohde/poista`, data, { headers: authHeader() } );
  } 

  muokkaaKohde(data) {
    return http.post(`/kohde/muokkaa`, data, { headers: authHeader() } );
  }

  uusiKohde(data) {
    return http.post(`/kohde/uusi`, data, { headers: authHeader() } );
  }

  haeLahinKohde(data) {
    return http.post(`/kohde/lahin`, data, { headers: authHeader() } );
  }

   haeTyypit() {
    return http.get("/tyyppi");
  }

}

export default new KohdeDataService();