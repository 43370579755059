<template>
  <section class="hero has-background-info">
    <div class="hero-body">
      <h1 class="title has-text-white">Lue kohteen QR-koodi</h1>
    <div><div class="box">
    <p>Viimeisin luettu huoltokoodi: <b><strong>{{ result }}</strong></b></p><br>
    <p>Viimeisin kohde: <b><strong>{{ kohdeqrnimi }}</strong></b></p>
    </div>
<div class="box">
    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
    </qrcode-stream>
</div>
  </div>
    </div>
    <aaltotausta />
  </section>
</template>

<script>
import Aaltotausta from "@/components/Aaltotausta.vue";
import KohdeDataService from "@/services/kohde.service.js";
import { QrcodeStream } from 'vue-qrcode-reader';
export default {

  components: { QrcodeStream, Aaltotausta },

  data () {
    return {
      isValid: undefined,
      kohdeqrnimi: null,
      camera: 'auto',
      result: null,
    }
  },

  computed: {

    currentUser() {
      return this.$store.state.auth.user;
    },

    validationPending () {
      return this.isValid === undefined
        && this.camera === 'off'
    },

    validationSuccess () {
      return this.isValid === true
    },

    validationFailure () {
      return this.isValid === false
    }
  },

  methods: {

    onInit (promise) {
      promise
        .catch(console.error)
        .then(this.resetValidationState)
    },

    resetValidationState () {
      this.isValid = undefined
    },

    async onDecode (content) {
      this.result = content
      this.turnCameraOff()
      this.merkitseHuoltoQR()
      this.turnCameraOn()
    },

    turnCameraOn () {
      this.camera = 'auto'
    },

    turnCameraOff () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },

    merkitseHuoltoQR() {
      var data = {
        TunnusID: this.currentUser.id,
        QRHuoltoKoodi: this.result
      };

      KohdeDataService.kohteenHuoltomerkintaQR(data)
        .then(response => {
          console.log(response.data);
          this.kohdeqrnimi = response.data.kohdeqrnimi;
          this.$buefy.toast.open({
            message: "Huoltomerkintä onnistui kohteelle: " + this.kohdeqrnimi,
            type: 'is-success'
          })
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: "Huoltomerkintä epäonnistui",
            type: 'is-danger'
          })
        });
    },
  }
}
</script>

<style>

</style>