import http from "@/http-common";
import authHeader from "./auth-header";

class JarjestelmaService {
  haeTyypit() {
    return http.get("/tyyppi");
  }

  jarjestelmaVersio() {
    return http.get("/jarjestelma/versio");
  }

  jarjestelmaLaiteseuranta() {
    return http.get("/jarjestelma/laiteseuranta");
  }

  jarjestelmaKoordinaatti() {
    return http.get("/jarjestelma/koordinaatti");
  }

  jarjestelmaKoordinaattiPaivita(data) {
    return http.post(`/jarjestelma/koordinaatti/paivita`, data, {
      headers: authHeader(),
    });
  }

  lisaaTyyppi(data) {
    return http.post(`/tyyppi/lisaa`, data, { headers: authHeader() });
  }

  poistaTyyppi(data) {
    return http.post(`/tyyppi/poista`, data, { headers: authHeader() });
  }

  jarjestelmaAsetukset() {
    return http.get("/jarjestelma/asetukset", { headers: authHeader() });
  }
}

export default new JarjestelmaService();
