import http from "@/http-common";

class TokenDataService {
  haePuhelinToken() {
    return http.get("/kohteet");
  }
  
  luoPuhelinToken(data) {
    return http.post("/kirjaudu/token/uusi", data );
  }

}

export default new TokenDataService();