<template>
  <div class="modal-card">
    <b-loading
      :is-full-page="true"
      v-model="latausStatus"
      :can-cancel="true"
    ></b-loading>
    <header class="modal-card-head">
      <p class="modal-card-title">Päivitä tiedote</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-field label="Valmiit tiedotteet">
        <b-select
          placeholder="Valitse tiedote"
          expanded
          v-model="valmisTiedote"
          @input="valmisTiedoteMethod"
        >
          <option value="Jäädytys aloitettu">Jäädytys aloitettu</option>
          <option value="Ladulla esteitä">Ladulla esteitä</option>
          <option value="Luistelujäässä halkeamia">
            Luistelujäässä halkeamia
          </option>
        </b-select>
      </b-field>
      <b-field label="Tiedote">
        <b-input
          maxlength="200"
          type="textarea"
          v-model="tiedoteTeksti"
        ></b-input>
      </b-field>
      <b-field>
        <b-checkbox-button v-model="NaytaTiedote" native-value="true" expanded>
          <b-icon v-if="NaytaTiedote == false" icon="minus"></b-icon>
          <b-icon v-if="NaytaTiedote == true" icon="check"></b-icon>
          <span>Tiedote näkyvillä</span>
        </b-checkbox-button>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$emit('close')">
        Sulje
      </button>
      <button class="button is-primary" @click="paivitaTiedote()">
        Tallenna
      </button>
    </footer>
  </div>
</template>

<script>
import KohdeDataService from "@/services/kohde.service.js";

export default {
  name: "KohdeTiedoteModal",
  data() {
    return {
      NaytaTiedote: "",
      latausStatus: true,
      tiedote: null,
      tiedoteTeksti: "",
      valmisTiedote: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    KohdeDataService.haeKohdeTiedote(this.$route.params.id)
      .then((response) => {
        this.tiedote = response.data;
        this.tiedoteTeksti = this.tiedote.Tiedote;
        if (this.tiedote.NaytaTiedote == 1) {
          this.NaytaTiedote = true;
        }
        if (this.tiedote.NaytaTiedote == 0) {
          this.NaytaTiedote = false;
        }
        this.latausStatus = false;
      })
      .catch((e) => {
        console.log(e);
        this.latausStatus = false;
      });
  },
  methods: {
    valmisTiedoteMethod() {
      this.tiedoteTeksti = this.valmisTiedote;
    },

    paivitaTiedote() {
      let NaytaTiedoteInt;
      if (this.NaytaTiedote == true) {
        NaytaTiedoteInt = "1";
      }
      if (this.NaytaTiedote == false) {
        NaytaTiedoteInt = "0";
      }

      var data = {
        TunnusID: this.currentUser.id,
        KohdeID: this.$route.params.id,
        Tiedote: this.tiedoteTeksti,
        NaytaTiedote: NaytaTiedoteInt,
      };

      KohdeDataService.tallennaKohdeTiedote(data)
        .then((response) => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Tiedotteen päivitys onnistui`,
            type: "is-success",
          });
          this.$emit("success-response", "true");
          this.$emit("close");
        })
        .catch((e) => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Tiedotteen päivitys epäonnistui`,
            type: "is-danger",
          });
          this.$emit("close");
        });
    },
  },
};
</script>

<style></style>
