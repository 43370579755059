import { Client, Databases } from "appwrite";

const client = new Client();
const databases = new Databases(client);

client
  .setEndpoint(process.env.VUE_APP_APPWRITE_URL)
  .setProject(process.env.VUE_APP_APPWRITE_PROJECT)



export { client, databases };
