<template>
<b-field group>
<b-button type="is-success is-medium" expanded :loading="nappilataa" @click="merkitseHuolto()" label="Merkitse huolto suoritetuksi" icon-left="wrench"></b-button>
</b-field>
</template>
<script>
import KohdeDataService from "@/services/kohde.service.js";

export default {
  name: "KohdesivuHuoltonappi",
  data() {
    return {
      submitted: false,
      nappilataa: false
    };
  },  
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {

    merkitseHuolto() {

      this.nappilataa = true;
      var data = {
        TunnusID: this.currentUser.id,
        KohdeID: this.$attrs.kohdeid

      };
      KohdeDataService.kohteenHuoltomerkinta(data)
        .then(response => {
          console.log(response.data);
          this.submitted = true;
          this.nappilataa = false;
          this.$emit('eventname', "paivitaApiKiitos")
        })
        .catch(e => {
          console.log(e);
          this.nappilataa = false;
        });
    },
  }
};
</script>

<style>

</style>