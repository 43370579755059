<template>
  <section>
    <b-loading :is-full-page="true" v-model="latausStatus" :can-cancel="true"></b-loading>
    <b-collapse 
    :open="true"
    class="card" 
    animation="slide" 
    aria-id="contentIdForA11y3">
    <template #trigger="props">
        <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open">
            <p class="card-header-title">
                Kohdeoikeudet
            </p>
            <a class="card-header-icon">
                <b-icon
                    :icon="props.open ? 'caret-up' : 'caret-down'"
                    >
                </b-icon>
            </a>
        </div>
    </template>

    <div class="card-content">
        <b-table
          :data="oikeus"
          :default-sort="defaultSort"
          :default-sort-direction="defaultSortDirection"
          :per-page="perPage"
          :paginated="isPaginated"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :pagination-rounded="isPaginationRounded"
          :selected.sync="valittuOikeus"
          focusable
        >
          <b-table-column field="Kohde.Nimi" label="Kohde" centered sortable>
            <template v-slot="props">
              {{ props.row.Kohde.Nimi }}
            </template>
          </b-table-column>
          <b-table-column field="Tyyppi" label="Tyyppi" centered>
            <template v-slot="props">
              {{ props.row.Kohde.Tyyppi }}
            </template>
          </b-table-column>
          <b-table-column field="Voi huoltaa" label="Voi huoltaa" centered>
            <template v-slot="props">
              <p v-if="props.row.VoiHuoltaa === 1">👍</p>
              <p v-else-if="props.row.VoiHuoltaa === 0">👎</p>
              <p v-else>🤷‍♂️</p>
            </template>
          </b-table-column>
          <b-table-column field="Voi muokata" label="Voi muokata" centered>
            <template v-slot="props">
              <p v-if="props.row.VoiMuokata === 1">👍</p>
              <p v-else-if="props.row.VoiMuokata === 0">👎</p>
              <p v-else>🤷‍♂️</p>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </b-collapse>
  <br>
  <b-button type="is-success is-medium" label="Lisää oikeus" icon-left="plus" @click="lisaaOikeusModal"></b-button>
  <b-button type="is-danger is-medium" label="Poista oikeus" icon-left="trash" class="ml-2" :disabled="!valittuOikeus"  @click="poistaOikeus(valittuOikeus.OikeusID)"></b-button>
  <b-button type="is-info is-medium" label="Poista valinta" icon-left="minus" class="ml-2" :disabled="!valittuOikeus" @click="valittuOikeus = null"></b-button>
  <br><br>
  </section>
</template>

<script>
import UserService from "@/services/user.service.js"
import YllapitoLisaaOikeus from './YllapitoLisaaOikeus.vue';

export default {
    name: "YllapitoRooliOikeus",
    data() {
        return {
            rooli: {},
            valittuOikeus: "",
            oikeus: "",
            defaultSortDirection: "asc",
            defaultSort: "Kohde.Nimi",
            perPage: 10,
            currentPage: 1,
            isPaginated: true,
            paginationPosition: "bottom",
            isPaginationRounded: false,
            isPaginationSimple: false,
            latausStatus: false,
        }
},
mounted() {
    this.haeRoolinOikeudet()
},
methods: {
    haeRoolinOikeudet() {
            UserService.haeRooliOikeudet(this.$attrs.rooli.RooliID)
            .then(response => {
            this.oikeus = response.data;
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });

        },

      poistaOikeus(oikeusid) {
      UserService.poistaOikeus(oikeusid)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Oikeuden poisto onnistui`,
            type: 'is-success'
          })
          this.haeRoolinOikeudet();
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Oikeuden poisto epäonnistui`,
            type: 'is-danger'
          })
          this.$emit('close')
        });
    },

        lisaaOikeusModal() {
        this.$buefy.modal.open({
          parent: this,
          component: YllapitoLisaaOikeus,
          hasModalCard: true,
          customClass: "custom-class",
          trapFocus: true,
          events: {
            'success-response': value => {
            console.log(value)
            console.log("Oikeus lisätty, haetaan API-rajapinnasta päivitetyt oikeudet")
            this.haeRoolinOikeudet();
          }
          },
          props: {
            "RooliID": this.$attrs.rooli.RooliID,
            "RooliNimi": this.$attrs.rooli.RooliNimi
          },
        });
      },
}
}
</script>

<style>

</style>