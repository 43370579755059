<template>
  <section>
    <div class="container">
      <div class="notification has-background-light">
        <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
          :mobile-mode="mobileMode"
        >
          <b-step-item
            step="1"
            label="Perustiedot"
            :clickable="isStepsClickable"
          >
            <h1 class="title has-text-centered">Perustiedot</h1>
            <div class="block">
            <b-field label="Nimi" :label-position="labelPosition">
                <b-input placeholder="Kirjoita nimi" v-model="KohdeNimi" required size="is-medium"></b-input>
            </b-field>

            <b-field label="Tyyppi">
              <b-select placeholder="Valitse tyyppi" size="is-medium" v-model="KohdeTyyppi" expanded required>
                <option v-for="tyyppi in tyypit" :key=tyyppi.TyyppiID :value=tyyppi.TyyppiNimi>{{ tyyppi.TyyppiNimi }}</option>
              </b-select>
            </b-field>

            <b-field label="Esittely" class="has-background-color-dark">
              <vue-editor v-model="KohdeEsittely" :editorToolbar="customToolbar" style="background-color:white;"></vue-editor>
            </b-field>

            <!-- <b-field label="Karttalinkki" :label-position="labelPosition">
                  <b-input placeholder="Anna osoite upotettavalle kartalle" size="is-medium" v-on:blur="karttalinkkiBlur()" type="url" v-model="KohdeKarttalinkki"></b-input>
            </b-field> -->
            <b-field label="Sijainti">
            <GmapMap
            :center="marker"
            :zoom="16"
            :clickable="true"
            @click="getMarkerPosition($event)"
            style="width: 100%; height: 500px"
            ref="gmap">
            <GmapMarker :position="marker" :draggable="true" @dragend="getMarkerPosition($event)"/>
            </GmapMap>
          </b-field>
          <b-button type="is-success" @click="haeGPSSijainti">Keskitä omaan GPS sijaintiin</b-button>

            <!-- <p class="has-text-left" v-if="KarttalinkkiBlurInfo == 1">Esikatselu:</p>
            <iframe v-if="KarttalinkkiBlurInfo == 1" :src="KohdeKarttalinkki" width="100%" height="400px" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
            <!-- <br>
            <b-field label="Koordinaatti LAT" :label-position="labelPosition">
                <b-input type="number" step="any" placeholder="65.230403" v-model="KohdeKoordLAT" size="is-medium"></b-input>
            </b-field>

            <b-field label="Koordinaatti LON" :label-position="labelPosition">
                <b-input type="number" step="any" placeholder="23.2334" v-model="KohdeKoordLON" size="is-medium"></b-input>
            </b-field> -->

            </div>
          </b-step-item>

          <b-step-item
            step="2"
            label="Lisätiedot"
            :clickable="isStepsClickable"
            :type="{ 'is-success': isProfileSuccess }"
          >
            <h1 class="title has-text-centered">Lisätiedot</h1>

            <b-field label="Onko kohde kaupungin/kunnan ylläpidossa?">
              <b-switch size="is-medium" v-model="KohdeOmaYllapito"
                  true-value="Kyllä"
                  false-value="Ei">
                  {{ KohdeOmaYllapito }}
              </b-switch>
          </b-field>

            <b-field label="Onko kohteessa valaistus?">
                <b-switch size="is-medium" v-model="KohdeValaistus"
                    true-value="Kyllä"
                    false-value="Ei">
                    {{ KohdeValaistus }}
                </b-switch>
            </b-field>

            <b-field label="Tietoja valaistuksesta" v-if="KohdeValaistus === 'Kyllä'">
                <b-input maxlength="200" size="is-medium" type="textarea" v-model="KohdeValaistusInfo" 
                placeholder="Valot ajastuksella/hämäräkytkimellä klo. 21.00 - 23.00"
                ></b-input>
            </b-field>

            <b-field label="Sallitaanko kommentointi?">
                <b-switch size="is-medium" v-model="KohdeKommentointi"
                    true-value="Kyllä"
                    false-value="Ei">
                    {{ KohdeKommentointi }}
                </b-switch>
            </b-field>

            <b-field label="Muutetaanko kohteen tila tuntiperusteisesti?">
                <b-switch size="is-medium" v-model="KohdeAutoTila"
                    true-value="Kyllä"
                    false-value="Ei">
                    {{ KohdeAutoTila }}
                </b-switch>
            </b-field>
            <section v-if="KohdeAutoTila === 'Kyllä'">

             <b-field label="Valitse tilamuutoksen keskimmäinen ajanjakso">
                <b-slider size="is-large" v-model="kohtalainentunnit" type="is-warning" :min="0" :max="48" :step="1" ticks>
                </b-slider>
            </b-field>

            <b-field label="Hyvä tila">
                <b-slider v-model="hyvatunnit" type="is-success" :min="0" :max="48" :step="1" ticks disabled indicator>
                </b-slider>
            </b-field>

            <b-field label="Kohtalainen tila">
                <b-slider v-model="kohtalainentunnit" type="is-warning" :min="0" :max="48" :step="1" ticks disabled indicator>
                </b-slider>
            </b-field>

            <b-field label="Huono tila">
                <b-slider v-model="huonotunnit" type="is-danger" :min="0" :max="48" :step="1" ticks disabled indicator>
                </b-slider>
            </b-field>
            </section>
          </b-step-item>

          <b-step-item
            step="3"
            label="Kuvat"
            :clickable="isStepsClickable"
            :type="{ 'is-success': isProfileSuccess }"
          >
            <h1 class="title has-text-centered">Kuvat</h1>
            <section>
              <b-field>
                  <b-upload v-model="ladatutKuvat"
                      multiple
                      drag-drop
                      expanded
                      rounded
                      @input="onFileSelected"
                      accept=".jpg" required validationMessage="Valitse kuvat"
                      >
                      <section class="section">
                          <div class="content has-text-centered" style="background-color:transparent;">
                              <p>
                                  <b-icon
                                      icon="upload"
                                      size="is-large">
                                  </b-icon>
                              </p>
                              <p>Raahaa lisättävät kuvat tähän tai klikkaa ladataksesi kuvat</p>
                          </div>
                      </section>
                  </b-upload>
              </b-field>
      
              <div class="tags">
                  <span v-for="(file, index) in ladatutKuvat"
                      :key="index"
                      :class="{ 'tag is-danger valkyta': file.size > 1024 * 1024}"
                      class="tag is-primary" >
                      <strong v-if="file.size > 1024 * 1024">Tiedosto on liian suuri (raja 2MB) : </strong>
                      {{file.name}}
                      <button class="delete"
                          type="button"
                          size="is-medium"
                          @click="deleteDropFile(index)">
                      </button>
                  </span>
              </div>

          </section>
          </b-step-item>

          <b-step-item
            step="4"
            label="Yhteenveto"
            :clickable="isStepsClickable"
            :type="{ 'is-success': isProfileSuccess }"
          >
            <h1 class="title has-text-centered">Yhteenveto</h1>
            <table class="table is-fullwidth">
              <tr>
                <th>Nimi</th>
                <th>{{KohdeNimi}}</th>
              </tr>
              <tr>
                <th>Tyyppi</th>
                <th>{{KohdeTyyppi}}</th>
              </tr>
              <!-- <tr>
                <th>Karttalinkki</th>
                <th>{{KohdeKarttalinkki}}</th>
              </tr>   -->
              <tr>
                <th>Koordinaatti</th>
                <th>{{KohdeKoordLAT}} {{KohdeKoordLON}}</th>
              </tr>  
              <tr>
                <th>Esittely</th>
                <th><div v-html="KohdeEsittely"> </div></th>
              </tr>  
              <tr>
                <th>Kaupungin / kunnan ylläpidossa</th>
                <th>{{KohdeOmaYllapito}}</th>
              </tr> 
              <tr>
                <th>Valaistus</th>
                <th>{{KohdeValaistus}}</th>
              </tr> 
              <tr v-if="KohdeValaistus === 'Kyllä'">
                <th>Valaistusinfo</th>
                <th>{{KohdeValaistusInfo}}</th>
              </tr> 
              <tr>
                <th>Kommentointi</th>
                <th>{{KohdeKommentointi}}</th>
              </tr> 
              <tr>
                <th>Automaattinen tilamuutos</th>
                <th>{{KohdeAutoTila}}</th>
              </tr> 
              <tr v-if="KohdeAutoTila === 'Kyllä'">
                <th>Hyvä-tilan ajanjakso</th>
                <th>{{hyvatunnit}}</th>
              </tr> 
              <tr v-if="KohdeAutoTila === 'Kyllä'">
                <th>Kohtalainen-tilan ajanjakso</th>
                <th>{{kohtalainentunnit}}</th>
              </tr> 
              <tr v-if="KohdeAutoTila === 'Kyllä'">
                <th>Huono-tilan ajanjakso</th>
                <th>{{huonotunnit}}</th>
              </tr> 
            </table>
            
          <b-button size="is-medium" @click="lisaaKohde" type="is-success" v-if="KohdeNimi !== '' && KohdeTyyppi !== '' && KohdeKoordLAT !== '' && KohdeKoordLON !== ''">Lisää kohde</b-button>
          <b-notification
            v-if="KohdeNimi == '' || KohdeTyyppi == ''"
            type="is-danger"
            aria-close-label="Sulje ilmoitus"
            role="alert">
            Kohteelta puuttuu nimi tai tyyppi
          </b-notification>
          <b-notification
            v-if="KohdeKoordLAT == '' || KohdeKoordLON == ''"
            type="is-danger"
            aria-close-label="Sulje ilmoitus"
            role="alert">
            Koordinaattitiedot puuttuvat
          </b-notification>
          <br>
          <b-progress size="is-medium" show-value v-if="latausbar">{{latausteksti}}</b-progress>
          <br>
          <!-- <div v-if="KohdeKarttalinkki == ''">
          <br>
          <b-notification
            type="is-warning"
            aria-close-label="Sulje ilmoitus"
            role="alert">
            Kohteelle kannattaa lisätä karttalinkki
          </b-notification>
          </div> -->
          </b-step-item>
        </b-steps>
      </div>
    </div>
  </section>
</template>

<script>
import KuvaService from "@/services/kuva.service.js"
import KohdeDataService from "@/services/kohde.service.js";
import JarjestelmaService from "@/services/jarjestelma.service.js"
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      activeStep: 0,
      radioButton: '',
      latausteksti: '',
      latausbar: false,
      KarttalinkkiBlurInfo: '0',
      kohtalainentunnitmin: '',
      kohtalainentunnitmax: '',
      KohdeKoordLAT: '',
      KohdeKoordLON: '',
      hyvatunnit: [0,6],
      kohtalainentunnit: [6,8],
      huonotunnit: [8,48],
      KohdeNimi: '',
      KohdeKarttalinkki: '',
      KohdeEsittely : '',
      KohdeTyyppi: "",
      KohdeOmaYllapito: 'Ei',
      KohdeValaistus: 'Ei',
      KohdeValaistusInfo: '',
      KohdeKommentointi: 'Ei',
      KohdeAutoTila: 'Ei',
      KohdeUusiID: '',

      ladatutKuvat: [],

      tyypit: [],
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      isSwitched: false,
      hasNavigation: true,
      isProfileSuccess: true,
      lahetetty: false,
      marker: {lat:0.0, lng:0.0},
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ 'align': [] }],
        ['link'],
        ['clean'],
      ],

      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
    };
  }, 
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  watch: {
      'kohtalainentunnit': function(){
      this.kohtalainentunnitmin = this.kohtalainentunnit[0];
      this.kohtalainentunnitmax = this.kohtalainentunnit[1];
      this.hyvatunnit = ['0',this.kohtalainentunnitmin]
      this.huonotunnit = [this.kohtalainentunnitmax, '48']
      }
  },
  methods: {

    haeOletusKoordinaatit() {
          JarjestelmaService.jarjestelmaKoordinaatti()
            .then(response => {
            const oletuskoordinaatti = response.data;
            this.marker = {lat: parseFloat(oletuskoordinaatti.KoordinaattiLAT), lng: parseFloat(oletuskoordinaatti.KoordinaattiLON)}
            
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });
      },

    kuvienLataus(){

      let formData = new FormData();
      this.ladatutKuvat.forEach((image, i) => formData.append('images[' + i + ']', image));
      KuvaService.lahetakuvat(formData, this.KohdeUusiID)
      .then(response => {
        console.log(response.data);
        this.ladatutKuvat = []
      })
      .catch(e => {
        console.log(e);
      });

      },

    deleteDropFile(index) {
                this.ladatutKuvat.splice(index, 1)
     },

    haeGPSSijainti() {
      navigator.geolocation.getCurrentPosition(pos => {
      this.gettingLocation = false;
      this.location = pos;
      this.marker = {lat:this.location.coords.latitude, lng:this.location.coords.longitude}
      this.KohdeKoordLAT = this.location.coords.latitude
      this.KohdeKoordLON = this.location.coords.longitude
    }, err => {
      this.gettingLocation = false;
      this.errorStr = err.message;
    })
    },

    getMarkerPosition (event) {
            this.KohdeKoordLAT = event.latLng.lat(),
            this.KohdeKoordLON = event.latLng.lng()
            this.marker = {lat: event.latLng.lat(), lng:event.latLng.lng()}
      },
    
      karttalinkkiBlur() {
        if (this.KohdeKarttalinkki) {
          this.KarttalinkkiBlurInfo = 1;
          }
    },

      kohdeLisattyModal() {
          this.$buefy.dialog.alert({
              title: 'Ilmoitus',
              message: 'Kohde lisätty 👍',
              confirmText: 'Selvä pyy!',
              onConfirm: () => this.$router.push('/kohde/' + this.KohdeUusiID)
          })
      },

      kohdeEiLisatty() {
          this.$buefy.dialog.alert({
              title: 'Ilmoitus',
              message: 'Kohteen lisäys ei onnistunut 🥲',
              confirmText: 'Yritä myöhemmin uudelleen'
          })
      },

    haeTyyppiValinnat() {
      KohdeDataService.haeTyypit()
        .then(response => {
          this.tyypit = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    lisaaKohde() {

      this.latausbar = true;
      this.latausteksti = "Luodaan kohdetta"

      var data = {
        TunnusID: this.currentUser.id,
        KohdeNimi: this.KohdeNimi,
        KohdeTyyppi: this.KohdeTyyppi,
        KohdeKarttalinkki: this.KohdeKarttalinkki,
        KohdeEsittely: this.KohdeEsittely,
        KohdeOmaYllapito: this.KohdeOmaYllapito,
        KohdeValaistus: this.KohdeValaistus,
        KohdeValaistusInfo: this.KohdeValaistusInfo,
        KohdeKommentointi: this.KohdeKommentointi,
        KohdeAutoTila: this.KohdeAutoTila,
        KohdeAutoTilaMin: this.kohtalainentunnitmin,
        KohdeAutoTilaMax: this.kohtalainentunnitmax,
        KohdeKoordinaattiLAT: this.KohdeKoordLAT,
        KohdeKoordinaattiLON: this.KohdeKoordLON
      };

      KohdeDataService.uusiKohde(data)
        .then(response => {
          console.log(response.data);
          this.lahetetty = true;
          this.KohdeUusiID = response.data.KohdeID
          if (this.ladatutKuvat) {
            this.latausteksti = "Ladataan kuvia"
            this.kuvienLataus();
          }
          this.latausbar = false;
          this.kohdeLisattyModal();
        })
        .catch(e => {
          console.log(e);
          this.latausbar = false;
          this.kohdeEiLisatty();
        });
    }
  },
  mounted() {
    this.haeOletusKoordinaatit();
    this.haeTyyppiValinnat();

  }
};
</script>

<style>
.valkyta {
  animation: blinker 3s linear infinite ;
}

@keyframes blinker {
  0%    { opacity: 1; }
  20%   { opacity: 0.6; }
  80%   { opacity: 1; }
}

.keskitys {
    display: flex;
    align-items: center;
    justify-content: center;
}
table 
{
    table-layout:fixed;
    width:100%;
}
th
{
 overflow: hidden;
 text-overflow: ellipsis;
}
</style>