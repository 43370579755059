<template>
  <section class="hero has-background-info">
    <div class="hero-body">
      <h1 class="title has-text-white">Järjestelmän ylläpito</h1>
      <yllapito-jarjestelma-component></yllapito-jarjestelma-component>
    </div>
    <aaltotausta />
  </section>
</template>

<script>
import Aaltotausta from "@/components/Aaltotausta.vue"
import YllapitoJarjestelmaComponent from "@/components/YllapitoJarjestelmaComponent.vue"

export default {
  name: "YllapitoTyyppi",
  components: {
    Aaltotausta,
    YllapitoJarjestelmaComponent
  },
  data() {
    return {
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/kirjaudu");
    }
  },
};
</script>
<style>
table 
{
    table-layout:fixed;
    width:100%;
}
th
{
 overflow: hidden;
 text-overflow: ellipsis;
}
td
{
 overflow: hidden;
 text-overflow: ellipsis;
}
</style>