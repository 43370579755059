<template>
  <section class="hero has-background-info">
  <div class="hero-body">
    <h1 class="title has-text-white">Sitä mitä etsit, <strong>ei löydetty täältä</strong></h1>
    <p class="is-size-1">🕵️‍♂️</p>
  </div>
<aaltotausta/>
  </section>
</template>


<script>
import Aaltotausta from '@/components/Aaltotausta.vue'

export default {
    components: {
        Aaltotausta
    }
}
</script>

<style>

</style>