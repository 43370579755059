<template>
  <div class="modal-card" >
    <header class="modal-card-head">
      <p class="modal-card-title">Muokkaa roolia</p>
      <button type="button" class="delete" @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <b-field label="Nimi">
        <b-input value="" v-model="roolinimi"></b-input>
    </b-field>
    <b-field label="Kuvaus"
    :label-position="labelPosition">
    <b-input maxlength="200" type="textarea" v-model="roolikuvaus"></b-input>
</b-field>

    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$emit('close')">Sulje</button>
      <button class="button is-primary" @click="muokkaaRooliFunc()">Tallenna</button>
    </footer>
  </div>
</template>

<script>
import UserService from "@/services/user.service.js"

export default {
  name: "YllapitoMuokkaaRoolia",
  data() {
    return {
      latausStatus: true,
      rooliid: '',
      roolinimi: '',
      roolikuvaus: '',
    };
  },
  mounted() {
    this.rooliid = this.$attrs.RooliID
    this.roolinimi = this.$attrs.RooliNimi
    this.roolikuvaus = this.$attrs.RooliKuvaus
  },
  methods: {
    muokkaaRooliFunc() {
      var data = {
        RooliID: this.rooliid,
        RooliNimi: this.roolinimi,
        RooliKuvaus: this.roolikuvaus,
      };

      UserService.muokkaaRooli(data)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Roolin muokkaus onnistui`,
            type: 'is-success'
          })
          this.$emit('success-response', "true")
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Roolin muokkaus epäonnistui`,
            type: 'is-danger'
          })
          this.$emit('close')
        });
    },
  }
};
</script>