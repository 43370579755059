<template>
<h1 class="title has-text-white">{{ this.$attrs.otsikko }}</h1>
</template>

<script>
export default {

}
</script>

<style>

</style>