<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Lisää rooli</p>
      <button type="button" class="delete" @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <b-table
      :data="roolit"
      :per-page="perPage"
      :paginated="isPaginated"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :pagination-rounded="isPaginationRounded"
      :selected.sync="valittuRooli"
    >
      <b-table-column field="Nimi" label="Nimi">
        <template v-slot="props">
          {{ props.row.RooliNimi }}
        </template>
      </b-table-column>
      <b-table-column field="Kuvaus" label="Kuvaus">
        <template v-slot="props">
          {{ props.row.RooliKuvaus }}
        </template>
      </b-table-column>
    </b-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$emit('close')">Sulje</button>
      <button class="button is-primary" @click="lisaaKayttajaRooli()">Tallenna</button>
    </footer>
  </div>
</template>

<script>
import UserService from "@/services/user.service.js";

export default {
  name: "YllapitoLisaaRooli",
  data() {
    return {
      roolit: '',
      valittuRooli: '',
      latausStatus: true,
    };
  },
  mounted() {
      this.haeRoolitKaikki()
  },
  methods: {
  
    haeRoolitKaikki() {
      UserService.haeRoolit()
            .then(response => {
            this.roolit = response.data;
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });
    },

    lisaaKayttajaRooli() {
      var id = this.$attrs.valittukayttaja
      var rooliid = this.valittuRooli.RooliID

      UserService.lisaaRooliKayttaja(id, rooliid)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Roolin lisäys onnistui`,
            type: 'is-success'
          })
          this.$emit('success-response', "true")
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Roolin lisäys epäonnistui`,
            type: 'is-success'
          })
          this.$emit('close')
        });
    },
  }
};
</script>