import http from "@/http-common";
import authHeader from './auth-header';
import authHeaderTokenOnly from './auth-header-onlytoken';

class KuvaService {

      lahetakuvat(data, id) {
        return http.post(`/kuva/lataa/${id}`, data, { headers: {"Content-Type": "multipart/form-data", "Authorization": authHeaderTokenOnly()} });
      }

      poistakuvat(data) {
        return http.post(`/kuva/poista`, data, { headers: authHeader() } );
      }
}

export default new KuvaService();