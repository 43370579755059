<template>
<div>

<b-notification
    v-if="this.$attrs.automaattinentilamuutos == 1"
    type="is-warning"
    aria-close-label="Close notification"
    role="alert">
    Kohteessa on automaattinen tilanmuuttuminen, joten tilanmuutos painikkeet eivät ole käytettävissä. <br><br><strong>Merkitse huolto niin tila vaihtuu automaattisesti.</strong> 
</b-notification>

<p class="is-size-4">Muuta kohteen tilaksi</p><br>
<b-field group>
<b-button :disabled="this.$attrs.automaattinentilamuutos == 1" type="is-success is-large" expanded :loading="nappilataahyva" @click="merkitseTila('Hyvä')" label="Hyvä" icon-left="thumbs-up"></b-button>
</b-field>
<b-field group>
<b-button :disabled="this.$attrs.automaattinentilamuutos == 1" type="is-warning is-large" expanded :loading="nappilataakohtalainen" @click="merkitseTila('Kohtalainen')" label="Kohtalainen" icon-left="minus"></b-button>
</b-field>
<b-field group>
<b-button :disabled="this.$attrs.automaattinentilamuutos == 1" type="is-danger is-large" expanded :loading="nappilataahuono" @click="merkitseTila('Huono')" label="Huono" icon-left="thumbs-down"></b-button>
</b-field>
<b-field group>
<b-button v-if="this.$attrs.kohdetila != 'Suljettu'" type="is-dark is-large" expanded :loading="nappilataasuljettu" @click="merkitseTila('Suljettu')" label="Sulje kohde" icon-left="times"></b-button>
</b-field>
<b-field group>
<b-button v-if="this.$attrs.automaattinentilamuutos == 1 && this.$attrs.kohdetila == 'Suljettu'" type="is-link is-large" :loading="nappilataasuljettu" @click="merkitseTila('Hyvä')" label="Avaa kohde" icon-left="check"></b-button>
</b-field>
<div class="card" v-if="onkoPaakayttaja() || onkoMuokkaaja()">
      <p class="card-header-title">
      Ylläpito-toiminnot
    </p><br>
<b-field group v-if="this.$attrs.poistettukaytosta != 1 && (onkoPaakayttaja() || onkoMuokkaaja())">
<b-button type="is-danger is-large" expanded :loading="nappilataapoistakaytosta" @click="poistaKaytosta(1)" label="Poista kohde käytöstä" icon-left="times"></b-button>
</b-field>
<b-field group v-if="this.$attrs.poistettukaytosta == 1 && (onkoPaakayttaja() || onkoMuokkaaja())">
<b-button type="is-success is-large" expanded :loading="nappilataapoistakaytosta" @click="poistaKaytosta(0)" label="Ota kohde käyttöön" icon-left="check"></b-button>
</b-field>
</div>
</div>
</template>

<script>
import KohdeDataService from "@/services/kohde.service.js";

export default {
  name: "KohdesivuTilanapit",
  data() {
    return {
      submitted: false,
      nappilataahyva: false,
      nappilataakohtalainen: false,
      nappilataahuono: false,
      nappilataasuljettu: false,
      nappilataapoistakaytosta: false
    };
  },  
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    onkoMuokkaaja(){
        if (this.currentUser) {
        for(var i=0; i<this.currentUser.roolit.length; i++) {
            console.log(this.currentUser.roolit[i].Rooli)
            if(this.currentUser.roolit[i].Rooli.RooliNimi == 'Muokkaaja') {
            console.log("Muokkaajan oikeudet käytössä")
            return true
        } 
    }
    }},
    onkoPaakayttaja() {
        if (this.currentUser) {
        for(var i=0; i<this.currentUser.roolit.length; i++) {
            console.log(this.currentUser.roolit[i].Rooli)
            if(this.currentUser.roolit[i].Rooli.RooliNimi == 'Pääkäyttäjä') {
            console.log("Pääkäyttäjän oikeudet käytössä")
            return true
        }  
    }
    }
    },
    merkitseTila(KohdeTila) {
      if (KohdeTila == 'Hyvä') {this.nappilataahyva = true;}
      if (KohdeTila == 'Kohtalainen') {this.nappilataakohtalainen = true;}
      if (KohdeTila == 'Huono') {this.nappilataahuono = true;}
      if (KohdeTila == 'Suljettu') {this.nappilataasuljettu = true;}

      var data = {
        TunnusID: this.currentUser.id,
        KohdeID: this.$attrs.kohdeid,
        KohdeTila: KohdeTila
      };

      KohdeDataService.kohteenTilamuutos(data)
        .then(response => {
          console.log(response.data);
          this.submitted = true;
          if (KohdeTila == 'Hyvä') {this.nappilataahyva = false;}
          if (KohdeTila == 'Kohtalainen') {this.nappilataakohtalainen = false;}
          if (KohdeTila == 'Huono') {this.nappilataahuono = false;}
          if (KohdeTila == 'Suljettu') {this.nappilataasuljettu = false;}
          this.$emit('eventname', "paivitaApiKiitos")
        })
        .catch(e => {
          console.log(e);
          if (KohdeTila == 'Hyvä') {this.nappilataahyva = false;}
          if (KohdeTila == 'Kohtalainen') {this.nappilataakohtalainen = false;}
          if (KohdeTila == 'Huono') {this.nappilataahuono = false;}
          if (KohdeTila == 'Suljettu') {this.nappilataasuljettu = false;}
          this.$emit('eventname', "paivitaApiKiitos")
        });
    },
    poistaKaytosta(PaallaVaiPois) {
      this.nappilataapoistakaytosta = true;

      var data = {
        TunnusID: this.currentUser.id,
        KohdeID: this.$attrs.kohdeid,
        PoistettuKaytosta: PaallaVaiPois
      };

      KohdeDataService.kohteenKaytostaPoisto(data)
        .then(response => {
          console.log(response.data);
          this.submitted = true;
          this.nappilataapoistakaytosta = false;
          this.$emit('eventname', "paivitaApiKiitos")
        })
        .catch(e => {
          console.log(e);
          this.nappilataapoistakaytosta = false;
          this.$emit('eventname', "paivitaApiKiitos")
        });
    },
  }
};
</script>

<style>
.keskitys {
    display: flex;
    align-items: center;
    justify-content: center;
}
.kelluvasen {
    float:left;
}
</style>