<template>
    <section>
        <b-loading :is-full-page="true" v-model="latausStatus" :can-cancel="true"></b-loading>
        <div class="tile is-ancestor">
        <div class="tile is-parent has-text-left">
            <div class="tile is-child box has-background-light">
       
                    <b-collapse 
                    :open="true"
                    class="card" 
                    animation="slide" 
                    aria-id="contentIdForA11y3">
                    <template #trigger="props">
                        <div
                            class="card-header"
                            role="button"
                            aria-controls="contentIdForA11y3"
                            :aria-expanded="props.open">
                            <p class="card-header-title">
                                Kaikki roolit
                            </p>
                            <a class="card-header-icon">
                                <b-icon
                                    :icon="props.open ? 'caret-up' : 'caret-down'"
                                    >
                                </b-icon>
                            </a>
                        </div>
                    </template>

                    <div class="card-content">
                        <b-table
                          v-if="roolit"
                          :data="roolit"
                          :default-sort="defaultSort"
                          :default-sort-direction="defaultSortDirection"
                          :per-page="perPage"
                          :paginated="isPaginated"
                          :pagination-simple="isPaginationSimple"
                          :pagination-position="paginationPosition"
                          :pagination-rounded="isPaginationRounded"
                          :selected.sync="valittuRooli"
                          focusable
                        >
                          <b-table-column field="RooliNimi" label="Nimi" centered sortable>
                            <template v-slot="props">
                              {{ props.row.RooliNimi }}
                            </template>
                          </b-table-column>
                          <b-table-column field="RooliKuvaus" label="Kuvaus" centered sortable>
                            <template v-slot="props">
                              {{ props.row.RooliKuvaus }}
                            </template>
                          </b-table-column>
                          <b-table-column field="RooliTyyppi" label="Tyyppi" centered sortable>
                            <template v-slot="props">
                              <p v-if="props.row.RooliTyyppi == 0">Sovellusoikeus</p>
                              <p v-if="props.row.RooliTyyppi == 1">Kohdeoikeus</p>
                            </template>
                          </b-table-column>
                          <b-table-column field="RooliLukittu" label="Lukittu" centered sortable>
                            <template v-slot="props">
                              <p v-if="props.row.RooliLukittu == 0">Ei</p>
                              <p v-if="props.row.RooliLukittu == 1">Kyllä</p>
                            </template>
                          </b-table-column>
                        </b-table>
                      </div>
                    </b-collapse>
                    <br>
                    <b-button type="is-success is-medium" label="Lisää rooli" icon-left="plus" class="ml-2 mt-2" @click="lisaaRooliModal"></b-button>
                    <b-button type="is-primary is-medium" label="Muokkaa roolia" icon-left="pen" class="ml-2 mt-2" :disabled="!valittuRooli || valittuRooli.RooliLukittu == 1" @click="muokkaaRooliModal"></b-button>
                    <b-button type="is-danger is-medium" label="Poista rooli" icon-left="trash" class="ml-2 mt-2" :disabled="!valittuRooli || valittuRooli.RooliLukittu == 1" @click="poistaRooli(valittuRooli.RooliID)"></b-button>
                    <b-button type="is-info is-medium" label="Poista valinta" icon-left="minus" class="ml-2 mt-2" :disabled="!valittuRooli" @click="valittuRooli = ''"></b-button>
                    <br><br>
                    <yllapito-oikeus v-if="this.valittuRooli.RooliTyyppi == 1" v-bind:rooli="valittuRooli" :key="valittuRooli"></yllapito-oikeus>
                </div>
            </div>
        </div>
      
    </section>
</template>

<script>
import UserService from "@/services/user.service.js"
import YllapitoOikeus from './YllapitoOikeus.vue';
import YllapitoLisaaRooli from './YllapitoLisaaRooli.vue';
import YllapitoRooliMuokkaus from './YllapitoRooliMuokkaus.vue';

export default {
  name: "YllapitoRooli",
  components: {
    YllapitoOikeus
  },
  data() {
      return {
          roolit: "",
          valittuRooli: "",
          defaultSortDirection: "asc",
          defaultSort: "RooliNimi",
          perPage: 10,
          currentPage: 1,
          isPaginated: true,
          paginationPosition: "bottom",
          isPaginationRounded: false,
          isPaginationSimple: false,
          latausStatus: false,
      }
  },
mounted() {
    this.haeKaikkiRoolit()
},
methods: {
    haeKaikkiRoolit() {
          UserService.haeRoolit()
            .then(response => {
            this.roolit = response.data;
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });
        },

        lisaaRooliModal() {
        this.$buefy.modal.open({
          parent: this,
          component: YllapitoLisaaRooli,
          hasModalCard: true,
          customClass: "custom-class",
          events: {
            'success-response': value => {
            console.log(value)
            console.log("Rooli lisätty, haetaan API-rajapinnasta päivitetyt roolit")
            this.haeKaikkiRoolit();
          }
          },
          trapFocus: true,
        });
      },

      muokkaaRooliModal() {
        this.$buefy.modal.open({
          parent: this,
          component: YllapitoRooliMuokkaus,
          hasModalCard: true,
          customClass: "custom-class",
          events: {
            'success-response': value => {
            console.log(value)
            console.log("Rooli lisätty, haetaan API-rajapinnasta päivitetyt roolit")
            this.haeKaikkiRoolit();
            this.valittuRooli = ''
          }
        },
          props: {
            "RooliID": this.valittuRooli.RooliID,
            "RooliNimi": this.valittuRooli.RooliNimi,
            "RooliKuvaus": this.valittuRooli.RooliKuvaus,
          },
          trapFocus: true,
        });
      },

      poistaRooli(rooliid) {

        this.$buefy.dialog.confirm({
            title: 'Roolin poisto',
            message: 'Poistetaanko rooli?',
            onConfirm: () => this.poistoRooliConfirm(rooliid),
            cancelText: 'Ei',
            confirmText: 'Kyllä',
            type: 'is-danger',
            hasIcon: true,
        })
    },

    poistoRooliConfirm(rooliid){
      UserService.poistaRooli(rooliid)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Roolin poisto onnistui`,
            type: 'is-success'
          })
          this.haeKaikkiRoolit();
          this.valittuRooli = ''
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Roolin poisto epäonnistui`,
            type: 'is-danger'
          })
          this.$emit('close')
        });
    }
}
}
</script>

<style>

</style>