<template>
<div>
<p class="title has-text-black-ter">Esittely</p>
<div v-if="this.$attrs.esittely" v-html="this.$attrs.esittely" class="has-text-left"></div>
<p v-if="!this.$attrs.esittely">Pöh, kukaan ei ole vielä esittelyä kirjoittanut</p>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>