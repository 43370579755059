import axios from 'axios';
import authHeader from './auth-header';

const API_URL = asetukset.apipalvelin;

class TilastoService {
    
    kayttajatilasto(kayttajaid) {
        return axios.get(API_URL + `/tilasto/kayttaja/${kayttajaid}`, { headers: authHeader() } );
      }

}

export default new TilastoService();
import asetukset from '@/asetukset.js'