<template>
  <section>
  <b-collapse 
            class="card" 
            animation="slide" 
            aria-id="contentIdForA11y3"
            :open="false"
            >
   
                <div
                    class="card-header"
                    role="button"
                     slot="trigger" 
                     slot-scope="props"
                    >
                    <p class="card-header-title">
                        Suodattimet
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'angle-down' : 'angle-up'">
                        </b-icon>
                    </a>
                
            </div>
            <div class="card-content">
                <div class="content has-background-white" style="height:100%">
                    <tyyppisuodatus/><br>
                    <tilasuodatus/>
                </div>
            </div>
  </b-collapse>
  <br>
  <div class="columns is-centered">      
  </div>
  <kohdeluettelo/>


  </section>
</template>

<script>
import Kohdeluettelo from '@/components/Kohdeluettelo.vue'
import Tyyppisuodatus from '@/components/Tyyppisuodatus.vue'
import Tilasuodatus from '@/components/Tilasuodatus.vue'

export default {
  name: 'EtusivuEmbed',
  components: {
    Kohdeluettelo,
    Tyyppisuodatus,
    Tilasuodatus
  }
}

</script>