<template>
  <section class="hero has-background-info">
  <div class="container has-text-centered is-fluid">
  <br>
  <KohdeluetteloKartta/>
  </div>
  <aaltotausta/>
<keksi/>
  </section>
</template>

<script>

import KohdeluetteloKartta from '@/components/KohdeluetteloKartta.vue'
import Aaltotausta from '@/components/Aaltotausta.vue'
import Keksi from '@/components/CookieNappi.vue'

export default {
  name: 'Etusivu',
  components: {
    KohdeluetteloKartta,
    Aaltotausta,
    Keksi
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  }, 
  mounted() {
    console.log(process.env.ROOT_API)
  }
}

</script>
