<template>
<div class="buttons">
    <b-button type="is-primary is-medium" icon-left="street-view" class="nappi" :loading="sijaintiBtnLataus" @click="paikannaMinut" expanded><strong>{{sijaintiBtnTeksti}}</strong></b-button>
</div>
</template>

<script>
import KohdeDataService from "@/services/kohde.service.js";

export default {
  data() {
    return {
      location:null,
      gettingLocation: false,
      errorStr:null,
      sijaintiBtnLataus: false,
      sijaintiBtnTeksti: "Etsi lähin huoltokohde"
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
methods: {
    haeLahinKohdeBackend() {
      
      var data = {
        LAT: this.location.coords.latitude,
        LON: this.location.coords.longitude,
        TUNNUSID: this.currentUser.id,
        lahinKohdeID: null
      };

      KohdeDataService.haeLahinKohde(data)
        .then(response => {
          console.log(response.data);
          this.sijaintiBtnLataus = false;
          this.lahinKohdeID = response.data.KohdeID
          this.$router.push('/kohde/huolto/' + this.lahinKohdeID)
        })
        .catch(e => {
          console.log(e);
          this.sijaintiBtnLataus = false;
          this.sijaintiBtnTeksti = "Emme löytäneet lähintä kohdetta, GPS-sijainti voi olla puutteellinen"
          
        });
    },

    async getLocation() {
      
      return new Promise((resolve, reject) => {

        if(!("geolocation" in navigator)) {
          reject(new Error('Geolocation is not available.'));
        }

        navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, err => {
          reject(err);
        });

      });
    },
    async paikannaMinut() {

      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();
        this.sijaintiBtnTeksti = "Pieni hetki.."
        this.haeLahinKohdeBackend();
      } catch(e) {
        this.gettingLocation = false;
        this.sijaintiBtnTeksti = "Emme löytäneet lähintä kohdetta, GPS-sijainti voi olla puutteellinen tai sijainnin käyttö on estetty"
        this.errorStr = e.message;
      }
}
}
}
</script>

<style>
.nappi {
 white-space: normal !important;
 height: 100%;
 width: 100%;
 text-align: center;
 }
</style>