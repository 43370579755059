<template>
  <Kirjautuminen/>
</template>


<script>
import Kirjautuminen from '@/components/Kirjautuminen.vue'

export default {
  components: {
    Kirjautuminen
  }
}
</script>

<style>

</style>