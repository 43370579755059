import axios from 'axios';

const API_URL = asetukset.apipalvelin;

class AuthService {
  login(user) {
    return axios
      .post(API_URL + '/kirjaudu', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logintoken(user) {
    return axios
      .post(API_URL + '/kirjaudu/token', {
        puhelin: user.puhelin,
        token: user.token
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logintokenAdminGenerated(user) {
    return axios
      .post(API_URL + '/kirjaudu/token/generated', {
        puhelin: user.puhelin,
        token: user.token
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + '/luo-tunnus', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService()
import asetukset from '@/asetukset.js'