<template>
  <div class="tile is-child box has-background-light">
    <div v-if="this.$attrs.tila === 'Hyvä'">
      <p class="title has-text-black-ter">
        Tila
        <svg width="25" height="25">
          <rect width="25" height="25" rx="5" style="fill: rgb(72, 199, 142)" />
        </svg>
      </p>
    </div>
    <div v-else-if="this.$attrs.tila === 'Kohtalainen'">
      <p class="title has-text-black-ter">
        Tila
        <svg width="25" height="25">
          <rect
            width="25"
            height="25"
            rx="5"
            style="fill: rgb(255, 224, 138)"
          />
        </svg>
      </p>
    </div>
    <div v-else-if="this.$attrs.tila === 'Huono'">
      <p class="title has-text-black-ter">
        Tila
        <svg width="25" height="25">
          <rect width="25" height="25" rx="5" style="fill: rgb(241, 70, 104)" />
        </svg>
      </p>
    </div>
    <div v-else-if="this.$attrs.tila === 'Suljettu'">
      <p class="title has-text-black-ter">
        Tila
        <svg width="25" height="25">
          <rect width="25" height="25" rx="5" style="fill: rgb(41, 41, 41)" />
        </svg>
      </p>
    </div>
    <div class="has-text-left">
      <strong>Tyyppi:</strong> {{ this.$attrs.tyyppi }}<br /><br />
      <div v-if="this.$attrs.huollettu !== null">
        <strong>Huollettu:</strong>
        {{ new Date(this.$attrs.huollettu).toLocaleDateString() }}
        {{ new Date(this.$attrs.huollettu).toLocaleTimeString() }}<br />
      </div>
      <p v-if="!this.$attrs.huollettu">
        <strong>Huollettu:</strong> Ei merkittyjä huoltoja
      </p>
      <br />
      <p v-if="this.$attrs.valaistus != 1">
        <strong>Valaistus:</strong> Valaistusta ei ole tai tietoja ei ole
        saatavilla
      </p>
      <p v-if="this.$attrs.valaistus == 1">
        <strong>Valaistus:</strong> Kyllä 👍
      </p>
      <br />
      <div v-if="this.$attrs.valaistus == 1">
        <strong>Lisätietoja valaistuksesta:</strong>
        <p>{{ this.$attrs.valaistusinfo }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    success() {
      this.$buefy.toast.open({
        message: "Kiitos pisteistä!",
        type: "is-success",
        position: "is-bottom",
      });
    },
  },
};
</script>

<style></style>
