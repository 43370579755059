<template>
  <section>
    <b-loading is-full-page v-model="latausStatus" can-cancel></b-loading>

    <kartta-info-ikkuna
      v-if="naytaModal"
      v-bind:kohde="valittukohde"
      @close="naytaModal = false"
    ></kartta-info-ikkuna>

    <LaiteInfoIkkuna
      v-if="naytaModalLaite"
      v-bind:laite="latukone"
      @close="naytaModalLaite = false"
    />

    <div class="box">
      <GmapMap
        :center="center"
        :zoom="karttazoom"
        map-type-id="terrain"
        ref="gmap"
        :options="options"
        class="map"
      >
        <GmapCluster>
          <GmapMarker
            :key="index"
            v-for="(kohde, index) in kohteet"
            :position="kohdeSijainti(kohde)"
            :icon="kohdeIkoni(kohde)"
            :clickable="true"
            :draggable="false"
            @click="KohdeInfo(kohde)"
          />
          <div>
            <GmapMarker
              v-if="latukone && onkolaiteseuranta == 1"
              :position="latukone.sijainti"
              :icon="{
                url: 'https://app.seutupilvi.fi/v1/storage/buckets/654a0cb1caab74d615f5/files/latukone/view?project=65438766be19407a4d42',
                scaledSize: { width: 65, height: 65 },
              }"
              :draggable="false"
              :clickable="true"
              :optimized="false"
              @click="KohdeLaite()"
            >
            </GmapMarker>
          </div>
        </GmapCluster>
      </GmapMap>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import KarttaInfoIkkuna from "@/components/KarttaInfoIkkuna.vue";
import LaiteInfoIkkuna from "@/components/LaiteInfoIkkuna.vue";
import JarjestelmaService from "@/services/jarjestelma.service.js";
import { databases } from "@/services/appwrite.js";

export default {
  components: { KarttaInfoIkkuna, LaiteInfoIkkuna },
  name: "KohdeluetteloKarttanakyma",
  data() {
    return {
      center: { lat: 0.0, lng: 0.0 },
      latukone: null,
      latausStatus: true,
      onkolaiteseuranta: 0,
      location: null,
      gettingLocation: false,
      errorStr: null,
      naytaModal: false,
      naytaModalLaite: false,
      valittukohde: null,
      karttazoom: 14,
      options: {
        styles: [
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.haeOletusKoordinaatit();
    this.haeOnkoSeurantaKaytossa();
    if (this.onkolaiteseuranta == 1) {
      this.haeSeurattavatLaitteet();
    }

    this.haeSeurattavatLaitteet();
    if (this.currentUser) {
      this.$store.dispatch("haeKohteetJulkinen");
    } else {
      this.$store.dispatch("haeKohteetJulkinen");
    }
  },
  created() {
    if (!("geolocation" in navigator)) {
      this.errorStr = "Sijainti ei ole saatavilla.";
      return;
    }
    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.location = pos;
      },
      (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      }
    );
  },
  methods: {
    haeOletusKoordinaatit() {
      JarjestelmaService.jarjestelmaKoordinaatti()
        .then((response) => {
          const oletuskoordinaatti = response.data;
          this.center = {
            lat: parseFloat(oletuskoordinaatti.KoordinaattiLAT),
            lng: parseFloat(oletuskoordinaatti.KoordinaattiLON),
          };
          this.karttazoom = parseInt(oletuskoordinaatti.KarttaZoom);
          this.latausStatus = false;
        })
        .catch((e) => {
          console.log(e);
          this.latausStatus = false;
        });
    },

    haeOnkoSeurantaKaytossa() {
      JarjestelmaService.jarjestelmaLaiteseuranta()
        .then((response) => {
          this.onkolaiteseuranta = response.data.Kaytossa;
          this.latausStatus = false;
        })
        .catch((e) => {
          console.log(e);
          this.latausStatus = false;
        });
    },

    async haeSeurattavatLaitteet() {
      try {
        const response = await databases.getDocument(
          "laitteet",
          "laitedb",
          "6548a772a724d48995b0"
        );

        this.latukone = response;
        this.latukone.sijainti = {
          lat: this.latukone.SijaintiLAT,
          lng: this.latukone.SijaintiLON,
        };
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    KohdeInfo(kohde) {
      this.center = { lat: kohde.KoordinaattiLAT, lng: kohde.KoordinaattiLON };
      this.valittukohde = kohde;
      this.naytaModal = true;
    },
    KohdeLaite() {
      this.naytaModalLaite = true;
    },
    kohdeSijainti(kohde) {
      let position = {
        lat: kohde.KoordinaattiLAT,
        lng: kohde.KoordinaattiLON,
      };
      return position;
    },
    kohdeIkoni(kohde) {
      if (kohde.Tyyppi === "Hiihtolatu") {
        return {
          url: "https://app.seutupilvi.fi/v1/storage/buckets/654a0cb1caab74d615f5/files/hiihto/view?project=65438766be19407a4d42",
          scaledSize: { width: 55, height: 55 },
        };
      }

      if (kohde.Tyyppi === "Luistelukenttä") {
        return {
          url: "https://app.seutupilvi.fi/v1/storage/buckets/654a0cb1caab74d615f5/files/luistelu/view?project=65438766be19407a4d42",
          scaledSize: { width: 55, height: 55 },
        };
      }

      if (kohde.Tyyppi === "Pulkkamäki") {
        return {
          url: "https://app.seutupilvi.fi/v1/storage/buckets/654a0cb1caab74d615f5/files/pulkka/view?project=65438766be19407a4d42",
          scaledSize: { width: 55, height: 55 },
        };
      }

      if (kohde.Tyyppi === "Kuntoportaat") {
        return {
          url: "https://app.seutupilvi.fi/v1/storage/buckets/654a0cb1caab74d615f5/files/kuntoportaat/view?project=65438766be19407a4d42",
          scaledSize: { width: 55, height: 55 },
        };
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    ...mapState(["kohteet", "latausStatus", "valitutTilat", "valitutTyypit"]),
  },
};
</script>

<style>
.keskitys {
  display: flex;
  align-items: center;
  justify-content: center;
}
.map {
  height: 85vh;
  width: 100%;
}
</style>
