import Vue from 'vue'
import VueRouter from 'vue-router'
import Etusivu from '../views/Etusivu.vue'
import EtusivuKartta from '../views/EtusivuKartta.vue'
import EtusivuEmbed from '../views/Etusivu_Embed.vue'
import Kirjaudu from '../views/Kirjaudu.vue'
import KirjauduTokenYllapito from '../views/KirjauduTokenYllapito.vue'
import Kohdesivu_Julkinen from '../views/Kohdesivu_Julkinen.vue'
import Kohdesivu_Huolto from '../views/Kohdesivu_Huolto.vue'
import EiLoydy404 from '../views/EiLoydy404.vue'
import Profiili from '../views/Profiili.vue'
import TestaaToken from '../views/TestaaToken.vue'
import Kohdesivu_Valinta from '../views/Kohdesivu_Valinta.vue'
import Kohdesivu_Lisaa from '../views/Kohdesivu_Lisays.vue'
import Kohdesivu_Muokkaa from '../views/Kohdesivu_Muokkaus.vue'
import YllapitoKayttaja from '../views/YllapitoKayttaja.vue'
import YllapitoRooli from '../views/YllapitoRooli.vue'
import YllapitoTyyppi from '../views/YllapitoTyyppi.vue'
import YllapitoJarjestelma from '../views/YllapitoJarjestelma.vue'
import QRHuolto from '../views/QR_Huolto.vue'
import Ohje from '../views/Ohje.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Etusivu',
    component: Etusivu
  },
  {
    path: '/karttanakyma',
    name: 'EtusivuKartta',
    component: EtusivuKartta
  },
  {
    path: '/embed',
    name: 'EtusivuEmbed',
    component: EtusivuEmbed
  },
  {
    path: '/kirjaudu',
    name: 'Kirjaudu',
    component: Kirjaudu
  },
  {
    path: '/kirjaudu/generated/:token/:puhelin',
    name: 'KirjauduTokenYllapito',
    component: KirjauduTokenYllapito
  },
  {
    path: '/kohde/lisaa',
    name: 'Kohde-Lisaa',
    component: Kohdesivu_Lisaa
  },
  {
    path: '/kohde/muokkaa/:id',
    name: 'Kohde-Muokkaa',
    component: Kohdesivu_Muokkaa
  },
  {
    path: '/kohde/valinta',
    name: 'Kohde-Valinta',
    component: Kohdesivu_Valinta
  },
  {
    path: '/kohde/:id',
    name: 'Kohde-Info',
    component: Kohdesivu_Julkinen
  },
  {
    path: '/kohde/huolto/:id',
    name: 'Kohde-Huolto',
    component: Kohdesivu_Huolto
  },
  {
    path: '/profiili',
    name: 'profiili',
    component: Profiili
  },
  {
    path: '/qr-huolto',
    name: 'qr-huolto',
    component: QRHuolto
  },
  {
    path: '/ohje',
    name: 'ohje',
    component: Ohje
  },
  {
    path: '/yllapito/kayttaja',
    name: 'yllapitokayttaja',
    component: YllapitoKayttaja
  },
  {
    path: '/yllapito/rooli',
    name: 'yllapitorooli',
    component: YllapitoRooli
  },
  {
    path: '/yllapito/jarjestelma',
    name: 'yllapitojarjestelma',
    component: YllapitoJarjestelma
  },
  {
    path: '/yllapito/tyyppi',
    name: 'yllapitotyyppi',
    component: YllapitoTyyppi
  },
  {
  path: '/testaa-token',
  name: 'testaaToken',
  component: TestaaToken
  
  },
  {
    path: '*',
    component: EiLoydy404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  const adminPages = ['/profiili', '/yllapito','/kohde/lisaa','/kohde/valinta','/qr-huolto','/testaa-token','/ohje'];
  const authRequired = adminPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  //Jos ei ole autentikoitunut, ohjataan kirjautumissivulle
  if (authRequired && !loggedIn) {
    next('/kirjaudu');
  } else {
    next();
  }
});