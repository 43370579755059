<template>
  <div id="app">
    <valikko />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <p class="is-size-7">
      {{ jarjestelma.data.versio }}
      <a
        href="https://www.linkedin.com/in/rami-kyt%C3%B6l%C3%A4-4429871ba/"
        target="_blank"
        >@Ramiky</a
      >
    </p>
  </div>
</template>

<script>
import "@fontsource/roboto";
import Valikko from "./components/Valikko.vue";
import JarjestelmaService from "@/services/jarjestelma.service.js";

export default {
  name: "app",
  data() {
    return {
      jarjestelma: "",
    };
  },
  components: {
    Valikko,
  },
  mounted() {
    this.haeJarjestelmaVersio();
  },
  methods: {
    haeJarjestelmaVersio() {
      JarjestelmaService.jarjestelmaVersio()
        .then((response) => {
          this.jarjestelma = response;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
