<template>
    <footer>
    <cookie-law theme="dark-lime" message="Sivustomme käyttää evästeitä. Evästeet tekevät sivustomme käyttämisestä helppoa, nopeaa ja käyttäjäystävällistä." buttonText="Selvä juttu 👍"></cookie-law>
  </footer>
</template>

<script>
  import CookieLaw from 'vue-cookie-law'
  export default {
    components: { CookieLaw }
  }
</script>

<style>

</style>