import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue-apexcharts";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import * as VeeValidate from "vee-validate";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import linkify from "vue-linkify";
import GmapVue from "gmap-vue";
import { components } from "gmap-vue";

Vue.directive("linkified", linkify);

library.add(fas);

Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAPAPI,
  },
  installComponents: true,
});

Vue.component("GmapCluster", components.Cluster);

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
  customIconPacks: {
    fas: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x",
      },
      iconPrefix: "",
    },
  },
});
Vue.use(VeeValidate);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
