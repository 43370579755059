<template>
  <section class="hero has-background-info">
  <div class="hero-body">
  <b-loading
      is-full-page
      v-model="latausStatus"
      can-cancel
    >
  </b-loading>
  <kohdesivuOtsikko v-bind:otsikko="kohde.Nimi"/>
  <div class="tile is-ancestor">
    <div class="tile is-4 is-vertical is-parent">
    <div class="tile is-child box has-background-light">
                <div v-if="kohde.Tila === 'Hyvä'">
            <p class="title has-text-black-ter">Tila
            <svg width="25" height="25">
              <rect
                width="25"
                height="25"
                rx="5"
                style="fill: rgb(72, 199, 142)"
              />
            </svg>
            </p>
          </div>
          <div v-else-if="kohde.Tila === 'Kohtalainen'">
            <p class="title has-text-black-ter">Tila
            <svg width="25" height="25">
              <rect
                width="25"
                height="25"
                rx="5"
                style="fill: rgb(255, 224, 138)"
              />
            </svg>
            </p>
          </div>
          <div v-else-if="kohde.Tila === 'Huono'">
            <p class="title has-text-black-ter">Tila
            <svg width="25" height="25">
              <rect
                width="25"
                height="25"
                rx="5"
                style="fill: rgb(241, 70, 104)"
              />
            </svg>
            </p>
          </div>
          <div v-else-if="kohde.Tila === 'Suljettu'">
            <p class="title has-text-black-ter">Tila
            <svg width="25" height="25">
              <rect
                width="25"
                height="25"
                rx="5"
                style="fill: rgb(41, 41, 41)"
              />
            </svg>
            </p>
          </div>
          <br>
      <kohdesivuViimeisinhuolto v-bind:huollettu="kohde.Huollettu" />
      <kohdesivuHuoltonappi v-bind:kohdeid="kohde.KohdeID" @eventname="paivitaApiKiitos" />
      <kohdesivuTiedotenappi v-bind:kohdeid="kohde.KohdeID" @eventname="paivitaApiKiitos" />
      <div v-if="kohde.QRHuolto">
      <p><strong>Kohteen QR-koodi</strong></p><br>
      <a @click="isImageModalActive = true"><qrcode-vue :value="kohde.QRHuolto" size="150" level="H"></qrcode-vue></a>
      </div>
    </div>
    </div>
    <div class="tile is-parent">
      <div class="tile is-child box has-background-light">
        <kohdesivu-tilanapit v-bind:kohdeid="kohde.KohdeID" v-bind:kohdetila="kohde.Tila"  v-bind:poistettukaytosta="kohde.PoistettuKaytosta" v-bind:automaattinentilamuutos="kohde.AutomaattinenTilamuutos" @eventname="paivitaApiKiitos"/>
      </div>
    </div>
        <div class="tile is-parent">
      <div class="tile is-child box has-background-light">
        <kohdesivu-loki v-bind:kohdeid="kohde.KohdeID" :key="huoltolokikey"/>
      </div>
    </div>
  </div> 
    </div>
  <aaltotausta/>
          <b-modal v-model="isImageModalActive">
            <div class="box">
            <p>
                <strong>{{kohde.Nimi}} - {{kohde.Tyyppi}}</strong><br><br>
                <qrcode-vue :value="kohde.QRHuolto" size="200" level="H"></qrcode-vue><br><br>
                <b-button type="is-dark is-medium" onclick="window.print()" label="Tulosta" icon-left="print"></b-button>
            </p>
            </div>
        </b-modal>
  </section>
</template>

<script>
import KohdeDataService from "@/services/kohde.service.js";
import QrcodeVue from 'qrcode.vue';

import kohdesivuOtsikko from '@/components/KohdesivuOtsikko.vue'
import kohdesivuHuoltonappi from '@/components/KohdesivuHuoltonappi.vue'
import kohdesivuTiedotenappi from '@/components/KohdesivuTiedotenappi.vue'
import kohdesivuTilanapit from '@/components/KohdesivuTilanapit.vue'
import kohdesivuViimeisinhuolto from '@/components/KohdesivuViimeisinhuolto.vue'
import kohdesivuLoki from '@/components/KohdesivuLoki.vue'
import Aaltotausta from '@/components/Aaltotausta.vue'

export default {
  components: {
    kohdesivuOtsikko,
    kohdesivuTiedotenappi,
    kohdesivuHuoltonappi,
    kohdesivuTilanapit,
    kohdesivuViimeisinhuolto,
    kohdesivuLoki,
    QrcodeVue,
    Aaltotausta
  },
    data () {
    return {
      kohde: [],
      huoltolokikey: 0,
      latausStatus: true,
      isImageModalActive: false
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {

    renderoiKomponenttiUudelleen() {
      this.huoltolokikey += 1;
    },

    paivitaApiKiitos  (variable) {
        this.parentvariable = variable
        if (variable == 'paivitaApiKiitos')
        {
          console.log("Tietojen päivitystä API:n kautta pyydetty")
          this.haeKohteenTiedot();
          this.renderoiKomponenttiUudelleen();
        }
    },

    haeKohteenTiedot() {
      KohdeDataService.haeKohde(this.$route.params.id)
        .then(response => {
          this.kohde = response.data;
          this.latausStatus = false;
        })
        .catch(e => {
          console.log(e);
          this.latausStatus = false;
        });
    },
  },
  mounted () {

    if (!this.currentUser) {
      this.$router.push('/kirjaudu');
    }

    this.haeKohteenTiedot();
  }
}
</script>

<style>

</style>