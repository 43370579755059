import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { auth } from './auth.module';
import router from '@/router'
import KohdeDataService from "@/services/kohde.service.js";


Vue.use(Vuex) 
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  state: {
    kohde: [],
    kohteet: [],
    latausStatus: false,
    valitutTyypit: [] ,
    valitutTilat: [],
    lumisade: false
  },
  mutations: {
    AsetaKohde (state, kohde) {
      state.kohde = kohde
    },
    AsetaKohteet (state, kohteet) {
      state.kohteet = kohteet
    },
    AsetaLatausStatus (state, uusiLatausStatus) {
      state.latausStatus = uusiLatausStatus
    },
    AsetaLumisade (state) {
      state.lumisade = !state.lumisade
    },
      AsetaTilat (state, valitutTilat) {
        state.valitutTilat = valitutTilat;
    },
      AsetaTyypit (state, valitutTyypit) {
        state.valitutTyypit = valitutTyypit;
    }
  },
  actions: {
    haeKohteet ({ commit }, { kayttajaid }) {
      commit('AsetaLatausStatus', true)
      KohdeDataService.haeKohteetKayttaja(kayttajaid)
        .then(r => r.data)
        .then(kohteet => {
        commit('AsetaLatausStatus', false)
        commit('AsetaKohteet', kohteet)
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            this.dispatch('auth/logout');
            router.push('/kirjaudu');
          }  
         if (error.response && error.response.status === 422) {
            this.dispatch('auth/logout');
            router.push('/kirjaudu');
          }
          else {
            console.log(error)
          }
        })
    },
    haeKohteetJulkinen ({ commit }) {
      commit('AsetaLatausStatus', true)
      KohdeDataService.haeKohteetJulkinen()
        .then(r => r.data)
        .then(kohteet => {
        commit('AsetaLatausStatus', false)
        commit('AsetaKohteet', kohteet)
        })
        .catch(error => console.log(error))
    },
    haeKohde ({ commit }, id) {
      commit('AsetaLatausStatus', true)
      KohdeDataService.haeKohde(id)
        .then(r => r.data)
        .then(kohde => {
        commit('AsetaLatausStatus', false)
        commit('AsetaKohde', kohde)
        })
        .catch(error => console.log(error))
    }
  },
  modules: {
    auth
  }
})
