<template>
  <div class="modal-card">
    
    <header class="modal-card-head">
      <p class="modal-card-title">Vaihda salasana</p>
      <button type="button" class="delete" @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <b-field label="Salasana">
        <b-input type="password" v-model="modalSalasana" required></b-input>
      </b-field>
      <b-field label="Syötä salasana uudelleen">
        <b-input type="password" v-model="modalSalasanaVarmistus" required></b-input>
      </b-field>

    </section>
    <footer class="modal-card-foot" style="justify-content: center;">
      <button class="button" type="button" @click="$emit('close')">Sulje</button>
      <button class="button is-primary" :disabled="modalSalasana != modalSalasanaVarmistus || modalSalasana == ''" @click="vaihdaSalasana()">Tallenna</button>
    </footer>
  </div>
</template>

<script>
import UserService from "@/services/user.service.js";

export default {
  name: "ProfiiliOmatTiedotModal",
  data() {
    return {
      modalSalasana: "",
      modalSalasanaVarmistus: ""
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    vaihdaSalasana() {
      var data = {
        TunnusID: this.currentUser.id,
        Salasana: this.modalSalasana
      };
      UserService.vaihdaSalasana(data)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Salasanan vaihto onnistui`,
            type: 'is-success'
          })
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Salasanan vaihto epäonnistui`,
            type: 'is-success'
          })
          this.$emit('close')
        });
    },
  }
};
</script>