<template>
    <section>
        <b-loading :is-full-page="true" v-model="latausStatus" :can-cancel="true"></b-loading>
        <div class="tile is-ancestor">
        <div class="tile is-parent has-text-left">
            <div class="tile is-child box has-background-light">
      
                    <b-collapse 
                    :open="true"
                    class="card" 
                    animation="slide" 
                    aria-id="contentIdForA11y3">
                    <template #trigger="props">
                        <div
                            class="card-header"
                            role="button"
                            aria-controls="contentIdForA11y3"
                            :aria-expanded="props.open">
                            <p class="card-header-title">
                                Kaikki tyypit
                            </p>
                            <a class="card-header-icon">
                                <b-icon
                                    :icon="props.open ? 'caret-up' : 'caret-down'"
                                    >
                                </b-icon>
                            </a>
                        </div>
                    </template>

                    <div class="card-content">
                        <b-table
                          v-if="tyypit"
                          :data="tyypit"
                          :default-sort="defaultSort"
                          :default-sort-direction="defaultSortDirection"
                          :per-page="perPage"
                          :paginated="isPaginated"
                          :pagination-simple="isPaginationSimple"
                          :pagination-position="paginationPosition"
                          :pagination-rounded="isPaginationRounded"
                          :selected.sync="valittuTyyppi"
                          focusable
                        >
                          <b-table-column field="TyyppiNimi" label="Nimi" centered sortable>
                            <template v-slot="props">
                              {{ props.row.TyyppiNimi }}
                            </template>
                          </b-table-column>
                          <b-table-column field="TyyppiKuvaus" label="Kuvaus" centered sortable>
                            <template v-slot="props">
                              {{ props.row.TyyppiKuvaus }}
                            </template>
                          </b-table-column>
                          <b-table-column field="TyyppiKuvake" label="Kuvake" centered sortable>
                            <template v-slot="props">
                              {{ props.row.TyyppiKuvake }}
                            </template>
                          </b-table-column>
                          <b-table-column field="TyyppiKarttaKuvake" label="Karttakuvake" centered sortable>
                            <template v-slot="props">
                              {{ props.row.TyyppiKarttaKuvake }}
                            </template>
                          </b-table-column>
                        </b-table>
                      </div>
                    </b-collapse>
                    <br>
                    <b-button type="is-success is-medium" label="Lisää tyyppi" icon-left="plus" @click="lisaaTyyppiModal"></b-button>
                    <b-button type="is-primary is-medium" label="Muokkaa tyyppiä" icon-left="pen" class="ml-2" :disabled="!valittuTyyppi || valittuTyyppi.RooliLukittu == 1" @click="muokkaaRooliModal"></b-button>
                    <b-button type="is-danger is-medium" label="Poista tyyppi" icon-left="trash" class="ml-2" :disabled="!valittuTyyppi || valittuTyyppi.RooliLukittu == 1" @click="poistaTyyppi(valittuTyyppi.TyyppiID)"></b-button>
                    <b-button type="is-info is-medium" label="Poista valinta" icon-left="minus" class="ml-2" :disabled="!valittuTyyppi" @click="valittuTyyppi = ''"></b-button>
                </div>
            </div>
        </div>
      
    </section>
</template>

<script>
import JarjestelmaService from "@/services/jarjestelma.service.js"
import YllapitoLisaaTyyppiModal from './YllapitoLisaaTyyppiModal.vue';
import YllapitoRooliMuokkaus from './YllapitoRooliMuokkaus.vue';

export default {
  name: "YllapitoTyyppi",
  data() {
      return {
          tyypit: "",
          valittuTyyppi: "",
          defaultSortDirection: "asc",
          defaultSort: "TyyppiNimi",
          perPage: 10,
          currentPage: 1,
          isPaginated: true,
          paginationPosition: "bottom",
          isPaginationRounded: false,
          isPaginationSimple: false,
          latausStatus: false,
      }
  },
mounted() {
    this.haeKaikkiTyypit()
},
methods: {
    haeKaikkiTyypit() {
          JarjestelmaService.haeTyypit()
            .then(response => {
            this.tyypit = response.data;
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });
        },

        lisaaTyyppiModal() {
        this.$buefy.modal.open({
          parent: this,
          component: YllapitoLisaaTyyppiModal,
          hasModalCard: true,
          customClass: "custom-class",
          events: {
            'success-response': value => {
            console.log(value)
            console.log("Rooli lisätty, haetaan API-rajapinnasta päivitetyt roolit")
            this.haeKaikkiTyypit();
          }
          },
          trapFocus: true,
        });
      },

      muokkaaRooliModal() {
        this.$buefy.modal.open({
          parent: this,
          component: YllapitoRooliMuokkaus,
          hasModalCard: true,
          customClass: "custom-class",
          events: {
            'success-response': value => {
            console.log(value)
            console.log("Rooli lisätty, haetaan API-rajapinnasta päivitetyt roolit")
            this.haeKaikkiTyypit();
            this.valittuTyyppi = ''
          }
        },
          props: {
            "RooliID": this.valittuTyyppi.RooliID,
            "RooliNimi": this.valittuTyyppi.RooliNimi,
            "RooliKuvaus": this.valittuTyyppi.RooliKuvaus,
          },
          trapFocus: true,
        });
      },

      poistaTyyppi(tyyppiid) {

        this.$buefy.dialog.confirm({
            title: 'Tyypin poisto',
            message: 'Poistetaanko tyyppi?',
            onConfirm: () => this.poistoTyyppiConfirm(tyyppiid),
            cancelText: 'Ei',
            confirmText: 'Kyllä',
            type: 'is-danger',
            hasIcon: true,
        })
    },

    poistoTyyppiConfirm(tyyppiid){

      var data = {
        TyyppiID: tyyppiid
      };

      JarjestelmaService.poistaTyyppi(data)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Tyypin poisto onnistui`,
            type: 'is-success'
          })
          this.haeKaikkiTyypit();
          this.valittuTyyppi = ''
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Tyypin poisto epäonnistui`,
            type: 'is-danger'
          })
          this.$emit('close')
        });
    }
}
}
</script>

<style>

</style>