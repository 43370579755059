<template>
  <section class="hero has-background-info">
  <div class="hero-body">
  <div class="container has-text-centered is-fluid">
    <kohdesivu-lisays-steps/>
  </div>
    </div>
  <aaltotausta/>
  </section>
</template>

<script>
import Aaltotausta from '@/components/Aaltotausta.vue'
import KohdesivuLisaysSteps from '@/components/KohdesivuLisaysSteps.vue'

export default {
  name: 'Kohdesivu_Valinta',
  components: {
    Aaltotausta,
    KohdesivuLisaysSteps
  }
};
</script>