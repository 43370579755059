<template>
<section>
<p class="is-size-4">Loki</p><br>
<b-table 

      :data="kohde.Lokit"
      :default-sort="defaultSort"
      :default-sort-direction="defaultSortDirection"
      :per-page="perPage"
      :paginated="isPaginated"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :pagination-rounded="isPaginationRounded"
      >

      <b-table-column field="Aikaleima" label="Aikaleima" sortable centered>
        <template v-slot="props">
          {{ new Date(props.row.Aikaleima).toLocaleDateString() }} {{ new Date(props.row.Aikaleima).toLocaleTimeString() }}
        </template>
      </b-table-column>

      <b-table-column field="Tapahtuma" label="Tapahtuma" centered>
        <template v-slot="props">
          {{ props.row.Tapahtuma }}
        </template>
      </b-table-column>

</b-table>
</section>
</template>

<script>
import KohdeDataService from "@/services/kohde.service.js";

export default {
  name: "KohdesivuLoki", 
  data () {
    return {
      kohde: "",
      defaultSortDirection: 'desc',
      defaultSort: 'Aikaleima',
      perPage: 5,
      currentPage: 1,
      isPaginated: true,
      paginationPosition: 'bottom',
      isPaginationRounded: false,
      isPaginationSimple: false
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted () {
      KohdeDataService.haeKohdeLoki(this.$route.params.id)
        .then(response => {
          console.log(response.data);
          this.kohde = response.data;
        })
        .catch(e => {
          if (e.response && e.response.status === 401) {
            this.$store.dispatch('auth/logout');
            this.$router.push('/kirjaudu');
          } else {
            console.log(e)
          }
          console.log(e);
        });
}
}
</script>

<style>

</style>