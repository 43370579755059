<template>
  <section>
    <div class="container">
      <div class="notification has-background-light">
        <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
          :mobile-mode="mobileMode"
        >
          <b-step-item
            step="1"
            label="Mitä haluat tehdä?"
            :clickable="isStepsClickable"
          >
            <h1 class="title has-text-centered">Valitse mitä haluat tehdä</h1>
            <div class="block">
              <b-radio v-if="onkoPaakayttaja() || onkoMuokkaaja()" v-model="radioButton" name="name" native-value="muokata">
                Muokata kohteen tietoja
              </b-radio>
              <b-radio v-model="radioButton" name="name" native-value="huolto">
                Muuttaa kohteen tilaa
              </b-radio>
              <b-radio v-if="onkoPaakayttaja() || onkoMuokkaaja()" v-model="radioButton" name="name" native-value="poista">
                Poistaa kohteen
              </b-radio>
            </div>
          </b-step-item>

          <b-step-item
            step="2"
            label="Kohteen valinta"
            :clickable="isStepsClickable"
            :type="{ 'is-success': isProfileSuccess }"
          >
            <h1 class="title has-text-centered">Valitse kohde luettelosta</h1>
            <b-field>
              <b-select placeholder="Valitse kohde" size="is-medium" v-model="kohdevalinta" expanded>
                <option v-for="kohde in kohteethuolto" :key=kohde.KohdeID :value=kohde.KohdeID>{{ kohde.Nimi }} | {{kohde.Tyyppi}}</option>
              </b-select>
            </b-field>
            <div>
              <b-button v-if="radioButton === 'huolto'" tag="router-link" type="is-medium" :to="'/kohde/huolto/' + kohdevalinta" label="Jatka" icon-left="tools">
              </b-button>

              <b-button v-if="radioButton === 'muokata'" tag="router-link" type="is-medium" :to="'/kohde/muokkaa/' + kohdevalinta" label="Jatka" icon-left="edit">
              </b-button>
              
              <b-button v-if="radioButton === 'poista'" type="is-medium" @click="vahvistaPoisto" label="Jatka" icon-left="trash">
              </b-button>
            </div>
          </b-step-item>

          <template v-if="customNavigation" #navigation="{ previous, next }">
            <b-button
              outlined
              type="is-danger"
              icon-pack="fas"
              icon-left="backward"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
            >
              Previous
            </b-button>
            <b-button
              outlined
              type="is-success"
              icon-pack="fas"
              icon-right="forward"
              :disabled="next.disabled"
              @click.prevent="next.action"
            >
              Next
            </b-button>
          </template>
        </b-steps>
      </div>
    </div>
  </section>
</template>

<script>
import KohdeDataService from "@/services/kohde.service.js";

export default {
  data() {
    return {
      activeStep: 0,
      radioButton: '',
      kohteethuolto: [],
      kohdevalinta: "",
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,

      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
    };
  },
    computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
   methods: {

    onkoMuokkaaja(){
        if (this.currentUser) {
        for(var i=0; i<this.currentUser.roolit.length; i++) {
            console.log(this.currentUser.roolit[i].Rooli)
            if(this.currentUser.roolit[i].Rooli.RooliNimi == 'Muokkaaja') {
            console.log("Muokkaajan oikeudet käytössä")
            return true
        } 
    }
    }},

    onkoHuoltaja(){
        if (this.currentUser) {
        for(var i=0; i<this.currentUser.roolit.length; i++) {
            console.log(this.currentUser.roolit[i].Rooli)
            if(this.currentUser.roolit[i].Rooli.RooliNimi == 'Huoltaja') {
            console.log("Muokkaajan oikeudet käytössä")
            return true
        } 
    }
    }},

    onkoPaakayttaja(){
        if (this.currentUser) {
        for(var i=0; i<this.currentUser.roolit.length; i++) {
            console.log(this.currentUser.roolit[i].Rooli)
            if(this.currentUser.roolit[i].Rooli.RooliNimi == 'Pääkäyttäjä') {
            console.log("Pääkäyttäjän oikeudet käytössä")
            return true
        } 
    }
    }},

    vahvistaPoisto() {
        this.$buefy.dialog.confirm({
            title: 'Kohteen poistaminen',
            message: 'Oletko varma että haluat <b>poistaa</b> kohteen?',
            confirmText: 'Poista kohde',
            cancelText: 'En',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              this.kohteenPoisto();
              this.$buefy.toast.open('Kohde poistettu 👋 <br> Voit jatkaa muita hommia sivulla');
            }
        })
    },

    haeKohdeHuoltoValinnat() {
      KohdeDataService.haeKohteetHuoltoOikeus(this.currentUser.id)
        .then(response => {
          let kohteethuoltotemp = response.data;
          kohteethuoltotemp = kohteethuoltotemp.sort((a, b) => (a.Nimi > b.Nimi) ? 1 : -1)
          this.kohteethuolto = kohteethuoltotemp;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    
    },
    kohteenPoisto() {
      var data = {
        TunnusID: this.currentUser.id,
        KohdeID: this.kohdevalinta
      };

      KohdeDataService.kohteenPoisto(data)
        .then(response => {
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    
  },
  mounted() {
    this.haeKohdeHuoltoValinnat();
  }
};
</script>

<style>
.keskitys {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>