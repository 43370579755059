export default class User {
    constructor(username,nimi, email, puhelin, password, viimeisinkirjautuminen, admin, jwttokenvanhenee) {
      this.username = username;
      this.nimi = nimi;
      this.email = email;
      this.puhelin = puhelin;
      this.password = password;
      this.viimeisinkirjautuminen = viimeisinkirjautuminen;
      this.admin = admin
      this.jwttokenvanhenee = jwttokenvanhenee
    }
  }