<template>
<b-field group>
<b-button type="is-info is-medium" expanded :loading="nappilataa" @click="lisaaTiedoteModal" label="Päivitä kohteen tiedote" icon-left="info"></b-button>
</b-field>
</template>

<script>
//import KohdeDataService from "@/services/kohde.service.js";
import KohdesivuTiedoteModal from './KohdesivuTiedoteModal.vue';

export default {
  name: "KohdesivuTiedotenappi",
  data() {
    return {
      submitted: false,
      nappilataa: false
    };
  },  
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {

    lisaaTiedoteModal() {
        this.$buefy.modal.open({
          parent: this,
          component: KohdesivuTiedoteModal,
          hasModalCard: true,
          customClass: "custom-class",
          trapFocus: true,
          animation: "zoom-out",
          events: {
            'success-response': value => {
            console.log(value)
            console.log("Tiedote lisätty, haetaan API-rajapinnasta päivitetyt tiedot")
            this.haeTiedot();
          }
          },
          props: {
            "kohde": this.$attrs.kohdeid
          },
        });
      },
  }
};
</script>

<style>

</style>