import { render, staticRenderFns } from "./Etusivu_Embed.vue?vue&type=template&id=29b3a8cc&"
import script from "./Etusivu_Embed.vue?vue&type=script&lang=js&"
export * from "./Etusivu_Embed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports