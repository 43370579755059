<template>
  <div class="modal-card" >
    <header class="modal-card-head">
      <p class="modal-card-title">Lisää tyyppi</p>
      <button type="button" class="delete" @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <b-field label="Nimi">
        <b-input value="" v-model="tyyppinimi"></b-input>
    </b-field>
    <b-field label="Kuvake">
      <b-input value="" v-model="tyyppikuvake"></b-input>
  </b-field>
    <b-field label="Kuvaus"
    :label-position="labelPosition">
    <b-input maxlength="200" type="textarea" v-model="tyyppikuvaus"></b-input>
</b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$emit('close')">Sulje</button>
      <button class="button is-primary" @click="lisaaTyyppi()">Tallenna</button>
    </footer>
  </div>
</template>

<script>
import JarjestelmaService from "@/services/jarjestelma.service.js"

export default {
  name: "YllapitoLisaaTyyppiModal",
  data() {
    return {
      latausStatus: true,
      tyyppinimi: '',
      tyyppikuvaus: '',
      tyyppikuvake: '',
    };
  },
  mounted() {

  },
  methods: {
    lisaaTyyppi() {
      var data = {
        TyyppiNimi: this.tyyppinimi,
        TyyppiKuvaus: this.tyyppikuvaus,
        TyyppiKuvake: this.tyyppikuvake
      };

      JarjestelmaService.lisaaTyyppi(data)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Tyypin lisäys onnistui`,
            type: 'is-success'
          })
          this.$emit('success-response', "true")
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Tyypin lisäys epäonnistui`,
            type: 'is-danger'
          })
          this.$emit('close')
        });
    },
  }
};
</script>