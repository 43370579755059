<template>
<div class="columns is-multiline is-mobile">
    <div class="column is-one-quarter is-narrow">
    <p class="has-text-weight-semibold">Tyyppi</p>
  </div>
  <div class="column">
<b-field grouped group-multiline>
  
<b-field>
    <b-switch native-value="Hiihtolatu" size="is-medium" :left-label='true' v-model="valitutTyypit">Hiihtolatu ⛷️</b-switch>
</b-field>

<b-field>
    <b-switch native-value="Luistelukenttä" size="is-medium" :left-label='true' v-model="valitutTyypit">Luistelukenttä 🏒</b-switch>
</b-field>

<b-field>
    <b-switch native-value="Pulkkamäki" size="is-medium" :left-label='true' v-model="valitutTyypit">Pulkkamäki 🛷</b-switch>
</b-field>

</b-field>
  </div>
</div>
</template>

<script>
export default {
  computed: {
    valitutTyypit: {
      /* By default get() is used */
      get() {
        return this.$store.state.valitutTyypit
      },
      /* We add a setter */
      set(valitutTyypit) {
        this.$store.commit('AsetaTyypit', valitutTyypit)
      }
    }
  }
}
</script>

<style>

</style>