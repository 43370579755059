<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card has-text-left">
        <header class="modal-card-head">
            <p class="modal-card-title">{{this.$attrs.kohde.Nimi}}</p>
            <button class="delete" aria-label="close" @click="$emit('close')"></button>
          </header>
        <div class="modal-content">
        </div>
        <section class="modal-card-body">
            <strong>Tyyppi:</strong> {{ this.$attrs.kohde.Tyyppi }}<br><br>
            <div v-if="this.$attrs.huollettu !== null">
                <strong>Huollettu:</strong>
                {{ new Date(this.$attrs.kohde.Huollettu).toLocaleDateString() }} {{ new Date(this.$attrs.kohde.Huollettu).toLocaleTimeString() }}<br><br>
          </div>
          <strong>Esittely:</strong>
          <div v-if="this.$attrs.kohde.Esittely" v-html="this.$attrs.kohde.Esittely"></div>
          </section>
        <footer class="modal-card-foot">
            <button class="button" aria-label="close" @click="$emit('close')">Sulje</button>
        </footer>
        </div>
      </div>
</template>

<script>
export default {
  name: 'KarttaInfoIkkuna',
}
</script>

<style>

</style>