<template>
  <section class="hero has-background-info">
    <b-loading :is-full-page="true" v-model="latausStatus" :can-cancel="true"></b-loading>
    <div class="hero-body">
      <h1 class="title has-text-white">Käyttäjien ylläpito</h1>
      <div class="tile is-ancestor">
        <div class="tile is-parent is-8">
          <div class="tile is-child box has-background-light">
            <strong>Käyttäjät</strong><br /><br />
            <div class="box">
              <b-table
                :data="kayttajat"
                :default-sort="defaultSort"
                :default-sort-direction="defaultSortDirection"
                :per-page="perPage"
                :paginated="isPaginated"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :pagination-rounded="isPaginationRounded"
                :selected.sync="valittuKayttaja"
                focusable
              >
                <b-table-column field="Nimi" label="Nimi" centered sortable>
                  <template v-slot="props" >
                    <p v-if="props.row.Admin == 1">{{ props.row.Nimi }} 🧜‍♂️</p>
                    <p v-if="props.row.Admin == 0">{{ props.row.Nimi }}</p>
                  </template>
                </b-table-column>
                <b-table-column field="Sähköposti" label="Sähköposti" centered>
                  <template v-slot="props">
                    {{ props.row.Sahkoposti }}
                  </template>
                </b-table-column>
                <b-table-column field="Puhelin" label="Puhelin" centered sortable>
                  <template v-slot="props">
                    <p v-if="props.row.Puhelin">+{{ props.row.Puhelin }}</p>
                    <p v-if="!props.row.Puhelin">Ei numeroa</p>
                  </template>
                </b-table-column>
                <b-table-column
                  field="ViimeisinKirjautuminen"
                  label="Viimeisin kirjautuminen"
                  sortable
                  centered
                >
                  <template v-slot="props">
                    {{
                      new Date(
                        props.row.ViimeisinKirjautuminen
                      ).toLocaleDateString()
                    }}
                    {{
                      new Date(
                        props.row.ViimeisinKirjautuminen
                      ).toLocaleTimeString()
                    }}
                  </template>
                </b-table-column>
              </b-table>
              <b-field class="has-text-left">
                <b-field grouped group-multiline>
                <b-button
                    label="Hae uudet tiedot"
                    type="is-success"
                    size="is-medium"
                    icon-left="minus"
                    class="m-2"
                    @click="haeKayttajat()" />
              <b-button
                  label="Poista valinta"
                  type="is-danger"
                  size="is-medium"
                  icon-left="minus"
                  class="m-2"
                  :disabled="!valittuKayttaja"
                  @click="valittuKayttaja = null" />
                </b-field>
          </b-field>
            </div>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child box has-background-light ">
            <strong>Lisää käyttäjä</strong><br /><br />
            <div class="box">
              <b-field label="Nimi">
                <b-input v-model="nimi"></b-input>
              </b-field>

              <b-field label="Puhelin (esim. +35840123456)">
                <b-input v-model="puhelin" type="text" maxlength="40" pattern="^[+]358*[\s\./0-9]*"> </b-input>
              </b-field>

              <b-field label="Sähköposti">
                <b-input v-model="sahkoposti" type="email" maxlength="60"> </b-input>
              </b-field>

              <b-field label="Salasana">
                <b-input v-model="salasana" type="password" password-reveal> </b-input>
              </b-field><br>
              <b-checkbox v-model="tunnuspaakayttaja">Pääkäyttäjä</b-checkbox><br><br>
              <b-checkbox v-model="tunnuksetspostiin">Lähetä tunnukset sähköpostiin</b-checkbox><br><br>
              
              <b-button size="is-medium" @click="lisaaKayttajaBtn" type="is-success" :disabled="nimi == ''">Lisää käyttäjä</b-button>
            </div>
          </div>
        </div>
      </div>  

      <yllapito-kayttaja-tiedot :key="valittuKayttaja.id" v-if="valittuKayttaja" v-bind:kayttaja="valittuKayttaja"></yllapito-kayttaja-tiedot>
    </div>
    <aaltotausta />
  </section>
</template>

<script>
import Aaltotausta from "@/components/Aaltotausta.vue"
import UserService from "@/services/user.service.js"
import YllapitoKayttajaTiedot from "@/components/YllapitoKayttajaTiedot.vue"

export default {
  name: "Yllapito",
  components: {
    Aaltotausta,
    YllapitoKayttajaTiedot
  },
  data() {
    return {
      kayttajat: [],
      nimi: '',
      sahkoposti: '',
      salasana: '', 
      puhelin: '',
      tunnuksetspostiin: '',
      tunnuspaakayttaja: '',
      defaultSortDirection: "desc",
      defaultSort: "ViimeisinKirjautuminen",
      perPage: 10,
      currentPage: 1,
      isPaginated: true,
      paginationPosition: "bottom",
      isPaginationRounded: false,
      isPaginationSimple: false,
      valittuKayttaja: '',
      checkboxPosition: 'left',
      checkboxType: 'is-primary',
      latausStatus: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {

      tunnusLisattyModal() {
        this.$buefy.dialog.alert({
            title: 'Ilmoitus',
            message: 'Tunnus luotu 👍',
            confirmText: 'Selvä pyy!',
            onConfirm: () => this.haeKayttajat()
        })
    },

      tunnusEiLisatty() {
          this.$buefy.dialog.alert({
              title: 'Ilmoitus',
              message: 'Tunnuksen luominen ei onnistunut 🥲',
              confirmText: 'Yritä myöhemmin uudelleen, jätän lomakkeen kentät ennalleen jos haluat yrittää lisätä tunnusta uudelleen'
          })
      },

    haeKayttajat() {
      this.latausStatus = true;
      UserService.luetteleKayttajat()
        .then((response) => {
          this.kayttajat = response.data;
          console.log(response.data);
          this.latausStatus = false;
        })
        .catch((e) => {
          console.log(e);
          this.latausStatus = false;
        });
    },
    lisaaKayttajaBtn(){
      console.log("Lähetetään API-kutsu tunnuksen luomiseen")
      var data = {
        Nimi: this.nimi,
        Puhelin: this.puhelin,
        Sahkoposti: this.sahkoposti,
        Salasana: this.salasana,
        SahkopostiIlmoitus: this.tunnuksetspostiin,
        Admin: this.tunnuspaakayttaja
      };
      UserService.lisaaKayttaja(data)
        .then(response => {
          console.log(response.data);
          this.tunnusLisattyModal();
          this.nimi = "";
          this.puhelin = "";
          this.sahkoposti = "";
          this.salasana = "";
          this.tunnuksetspostiin = "";
          this.tunnuspaakayttaja = "";
        })
        .catch(e => {
          console.log(e);
          this.tunnusEiLisatty();
        });
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/kirjaudu");
    }
    this.haeKayttajat();
  },
};
</script>
<style>
table 
{
    table-layout:fixed;
    width:100%;
}
th
{
 overflow: hidden;
 text-overflow: ellipsis;
}
td
{
 overflow: hidden;
 text-overflow: ellipsis;
}
</style>