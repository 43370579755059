<template>
  <section class="hero has-background-info">
  <div class="hero-body">
  <b-loading
      is-full-page
      v-model="latausStatus"
      can-cancel
    >
  </b-loading>
  <kohdesivuOtsikko v-bind:otsikko="kohde.Nimi"/>
  <b-notification v-if="kohde.NaytaTiedote == 1" type="is-warning" aria-close-label="Sulje ilmoitus">{{kohde.Tiedote}}</b-notification>
  <div class="tile is-ancestor">
    <div class="tile is-4 is-vertical is-parent">
      <kohdesivuInfobox v-bind:tila="kohde.Tila" v-bind:tyyppi="kohde.Tyyppi" v-bind:huollettu="kohde.Huollettu" v-bind:valaistus="kohde.Valaistus" v-bind:valaistusinfo="kohde.ValaistusInfo" />
      <div class="tile is-child box has-background-light">
        <kohdesivuEsittely v-bind:esittely="kohde.Esittely"/>
        </div>
    </div>
    <div class="tile is-parent">
      <div class="tile is-child box has-background-light">
        <kohdesivuKartta v-bind:karttalinkki="kohde.Karttalinkki" v-bind:lat="kohde.KoordinaattiLAT" v-bind:lon="kohde.KoordinaattiLON"/>
      </div>
    </div>
  </div> 
  <div class="tile is-child box has-background-light">
    <kohdesivuKuvabox v-bind:kohdeid="kohde.KohdeID"/>
  </div>
  <br>
      <kohdesivu-kommentointi v-bind:kohdeid="kohde.KohdeID" v-if="kohde.Kommentointi == 1"/>
    </div>
    
  <aaltotausta/>
  </section>
</template>

<script>
import KohdeDataService from "@/services/kohde.service.js";
import kohdesivuOtsikko from '@/components/KohdesivuOtsikko.vue'
import kohdesivuInfobox from '@/components/KohdesivuInfobox.vue'
import kohdesivuEsittely from '@/components/KohdesivuEsittelybox.vue'
import kohdesivuKartta from '@/components/KohdesivuKarttabox.vue'
import kohdesivuKuvabox from '@/components/KohdesivuKuvabox.vue'
import kohdesivuKommentointi from '@/components/KohdesivuKommentointi.vue'
import Aaltotausta from '@/components/Aaltotausta.vue'

export default {
  components: {
    kohdesivuOtsikko,
    kohdesivuInfobox,
    kohdesivuEsittely,
    kohdesivuKuvabox,
    kohdesivuKartta,
    kohdesivuKommentointi,
    Aaltotausta
  },
    data () {
    return {
      kohde: "",
      kuvat: "",
      latausStatus: true
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted () {
      KohdeDataService.haeKohde(this.$route.params.id)
        .then(response => {
          this.kohde = response.data;
          this.latausStatus = false;
        })
        .catch(e => {
          console.log(e);
          this.latausStatus = false;
        });
  }
}
</script>

<style>

</style>