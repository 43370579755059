<template>
<section>
    <div v-if="!kuvat.Kuvat.length" class="has-text-centered">
        <p>Kohteesta ei ole lisätty vielä kuvia</p>
    </div>

    <b-carousel :autoplay="false" indicator-custom :indicator-inside="false" :overlay="gallery">
        <b-carousel-item v-for="(kuva, index) in kuvat.Kuvat" v-bind:key="index">
            <a class="image" v-if="gallery">
                <img :src="haeKuva(kuva.KuvaOID)">
            </a>
        </b-carousel-item>
        <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large"/>
        <template #indicators="props">
            <figure class="al image" :draggable="false" @click="switchGallery(true)">
                <img :draggable="false" :src="haeKuva(kuvat.Kuvat[props.i].KuvaOID)">
            </figure>
        </template>
    </b-carousel>

</section>
</template>

<script>
import KohdeDataService from "@/services/kohde.service.js";
import asetukset from '@/asetukset.js';

export default {
    data () {
    return {
      kohde: "",
      kuvat: "",
      latausStatus: true,
      gallery: false,
    }
    },
    methods: {

        switchGallery(value) {
            this.gallery = value
            if (value) {
                return document.documentElement.classList.add('is-clipped')
            } else {
                return document.documentElement.classList.remove('is-clipped')
            }
        },

        haeKuva(id) {
            let kuva = asetukset.apistatic + '/' + id + '.jpg'
            return kuva
        }
    },
    mounted()
    {
        KohdeDataService.haeKohdeKuvat(this.$route.params.id)
        .then(response => {
        this.kuvat = response.data;
        this.latausStatus = false;
        })
        .catch(e => {
        console.log(e);
        this.latausStatus = false;
        });
    }
}

</script>

<style>
.image img {
    aspect-ratio: auto;
    object-fit: contain;
    height:auto; 
    max-height:600px;;
}

.al img {
    border: 1px solid transparent;
    filter: grayscale(100%);
}
.carousel .carousel-indicator.has-custom img:hover {
    border: 1px solid transparent;
    filter: grayscale(0%);
}
.carousel .carousel-indicator.has-custom img{
    max-height: 20vh;
    object-fit: cover;
}
</style>