<template>
  <section class="hero has-background-info">
    <div class="hero-body">
      <h1 class="title has-text-white">Roolien ylläpito</h1>
      <yllapito-rooli-component></yllapito-rooli-component>
    </div>
    <aaltotausta />
  </section>
</template>

<script>
import Aaltotausta from "@/components/Aaltotausta.vue"
import YllapitoRooliComponent from "@/components/YllapitoRooliComponent.vue"

export default {
  name: "Yllapito",
  components: {
    Aaltotausta,
    YllapitoRooliComponent
  },
  data() {
    return {
      defaultSortDirection: "desc",
      defaultSort: "ViimeisinKirjautuminen",
      perPage: 10,
      currentPage: 1,
      isPaginated: true,
      paginationPosition: "bottom",
      isPaginationRounded: false,
      isPaginationSimple: false,
      valittuKayttaja: '',
      checkboxPosition: 'left',
      checkboxType: 'is-primary',
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/kirjaudu");
    }
    this.haeKayttajat();
  },
};
</script>
<style>
table 
{
    table-layout:fixed;
    width:100%;
}
th
{
 overflow: hidden;
 text-overflow: ellipsis;
}
td
{
 overflow: hidden;
 text-overflow: ellipsis;
}
</style>