<template>
  <div class="modal-card" >
    <header class="modal-card-head">
      <p class="modal-card-title">Lisää rooli</p>
      <button type="button" class="delete" @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <b-field label="Nimi">
        <b-input value="" v-model="roolinimi"></b-input>
    </b-field>
    <b-field label="Kuvaus"
    :label-position="labelPosition">
    <b-input maxlength="200" type="textarea" v-model="roolikuvaus"></b-input>
</b-field>
<b-field
label="Tyyppi">
<b-select placeholder="Tyyppi" expanded v-model="roolityyppi">
    <option value="1">Kohdeoikeus</option>
    <option value="0">Sovellusoikeus</option>
</b-select>
</b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$emit('close')">Sulje</button>
      <button class="button is-primary" @click="lisaaRooli()">Tallenna</button>
    </footer>
  </div>
</template>

<script>
import UserService from "@/services/user.service.js"

export default {
  name: "YllapitoLisaaRooli",
  data() {
    return {
      latausStatus: true,
      roolinimi: '',
      roolikuvaus: '',
      roolityyppi: '',
    };
  },
  mounted() {

  },
  methods: {
    lisaaRooli() {
      var data = {
        RooliNimi: this.roolinimi,
        RooliKuvaus: this.roolikuvaus,
        RooliTyyppi: this.roolityyppi
      };

      UserService.lisaaRooli(data)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Roolin lisäys onnistui`,
            type: 'is-success'
          })
          this.$emit('success-response', "true")
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Roolin lisäys epäonnistui`,
            type: 'is-danger'
          })
          this.$emit('close')
        });
    },
  }
};
</script>