<template>
  <section class="hero has-background-info">
    <div class="hero-body">
      <div class="container has-text-centered is-fluid">
        <div class="column is-4 is-offset-4">
          <h3 class="title has-text-white">Kirjaudu</h3>
          <div class="box">
            <b-notification
              type="is-danger"
              aria-close-label="Sulje ilmoitus"
              v-if="error === true"
              role="alert"
            >
              Kirjautuminen ei onnistunut, yritä uudelleen paremmalla onnella
            </b-notification>
            <form name="form" @submit.prevent="Kirjautuminen">
              <div class="field">
                <div class="control">
                  <input
                    class="input is-large"
                    v-model="user.username"
                    type="email"
                    autocomplete="username"
                    name="username"
                    placeholder="Sähköposti"
                    autofocus=""
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    class="input is-large"
                    v-model="user.password"
                    type="password"
                    autocomplete="current-password"
                    name="password"
                    placeholder="Salasana"
                    v-if="user.username"
                  />
                </div>
              </div>
              <button class="button is-block is-info is-large is-fullwidth" v-if="user.password">
                Kirjaudu
              </button>
            </form>
            <br />
            <button
              class="button is-block is-link is-large is-fullwidth nappi"
              @click="kysyPuhelin"
            >
              Kirjaudu ilman salasanaa
            </button>
          </div>
        </div>
      </div>
    </div>
    <aaltotausta />
  </section>
</template>

<script>
import User from "@/models/user";
import TokenDataService from "@/services/token.service.js";
import Aaltotausta from "@/components/Aaltotausta.vue";

export default {
  components: {
    Aaltotausta,
  },
  name: "Kirjautuminen",
  data() {
    return {
      user: new User("", ""),
      loading: false,
      puhelin: "",
      token: "",
      message: "",
      error: "",
      puhelinkooditoimitus: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profiili");
    }
  },
  methods: {
    kysyPuhelin() {
      this.$buefy.dialog.prompt({
        message: `Anna puhelinnumerosi?`,
        confirmText: "OK",
        cancelText: "Peru",
        canCancel: true,
        inputAttrs: {
          placeholder: "040123456",
          maxlength: 40,
          pattern: "^[0-9]*$",
        },
        trapFocus: true,
        onConfirm: (puhelin) => this.kysyPuhelinKoodi(puhelin),
      });
    },

    kysyPuhelinKoodi(puhelin) {
      this.user.puhelin = puhelin;
      var data = {
        puhelin: this.user.puhelin,
      };
      TokenDataService.luoPuhelinToken(data)
        .then((response) => {
          console.log(response.data);
          this.puhelinkooditoimitus = true;
          this.$buefy.toast.open({
            message: `Numeroon ${this.puhelin} lähetettiin vahvistuskoodi, koodi vanhenee 15 min kuluessa`,
            type: 'is-success'
          })
          this.$buefy.dialog.prompt({
            message: `Anna vahvistuskoodi?`,
            confirmText: "OK",
            cancelText: "Peru",
            inputAttrs: {
              placeholder: "123456",
              maxlength: 6,
              pattern: "^[0-9]*$",
            },
            trapFocus: true,
            onConfirm: (token) => this.vahvistaKoodiAPIsta(token),
          });
        })
        .catch((e) => {
          console.log(e);
          this.puhelinkooditoimitus = false;
          this.$buefy.toast.open({
            message: `Numeroon ${this.puhelin} ei voitu toimittaa vahvistuskoodia. Tarkista onko numero oikein`,
            type: 'is-danger'
          })
        });
    },

    vahvistaKoodiAPIsta(token) {
      this.user.token = token;
      this.$buefy.toast.open({
        message: `Vahvistuskoodin tarkistus käynnissä`,
        type: 'is-success'
      })
      this.TokenKirjautuminen();
    },

    Kirjautuminen() {
      this.loading = true;

      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/profiili");
          },
          (error) => {
            this.loading = false;
            this.error = true;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    TokenKirjautuminen() {
      this.loading = true;

      if (this.user.puhelin && this.user.token) {
        this.$store.dispatch("auth/logintoken", this.user).then(
          () => {
            this.$router.push("/profiili");
          },
          (error) => {
            this.loading = false;
            this.error = true;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>

<style>
.nappi {
 white-space: normal !important;
 height: 100%;
 width: 100%;
 text-align: center;
 }
</style>
