import axios from "axios";
import asetukset from '@/asetukset.js';

const API_URL = asetukset.apipalvelin;

export const HTTP = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    "Content-type": "application/json"
  }
});

export default HTTP;