<template>
  <section class="hero has-background-info">
    <div class="hero-body">
      <h1 class="title has-text-white">Ohjeet</h1>
      <div class="tile is-parent">
        <div class="tile is-child box has-background-light">
          <b-collapse
            :open="true"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y3"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open"
              >
                <p class="card-header-title">Kohteen huoltaminen</p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'caret-up' : 'caret-down'">
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content has-text-left">
              <ul>
                <li>
                  1. Napauta
                  <img src="../assets/logo.png" style="width: 20px" />-ikoniin,
                  josta pääset etusivulle
                </li>
                <li>
                  2. Valitse kohde, jota haluat huoltaa painamalla kohteen
                  <strong>Huolto-nappiin</strong>
                </li>
                <li>
                  3. Napauta kohtaan
                  <strong>Merkitse huolto suoritetuksi</strong>
                </li>
                <li>
                  4. Merkitse tarvittaessa kohteelle sopiva tila samalla (Hyvä,
                  Kohtalainen, Huono)
                </li>
              </ul>
            </div>
          </b-collapse>
          <br />
          <b-collapse
            :open="false"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y3"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open"
              >
                <p class="card-header-title">Kohteen tilan merkitseminen</p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'caret-up' : 'caret-down'">
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content has-text-left">
              <ul>
                <li>
                  1. Napauta
                  <img src="../assets/logo.png" style="width: 20px" />-ikoniin,
                  josta pääset etusivulle
                </li>
                <li>
                  2. Valitse kohde, jota haluat huoltaa painamalla kohteen
                  <strong>Huolto-nappiin</strong>
                </li>
                <li>
                  3. Merkitse kohteelle sopiva tila valitsemalla
                  <strong>(Hyvä, Kohtalainen, Huono)</strong>
                </li>
              </ul>
            </div>
          </b-collapse>
          <br />
          <b-collapse
            :open="false"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y3"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open"
              >
                <p class="card-header-title">Muutosloki</p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'caret-up' : 'caret-down'">
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content has-text-left">
              <p><strong>2023.11.28</strong></p>
              <ul>
                <li>Lisätty mahdollisuus laitteiden seuraamiseen kartalta</li>
                <li>Tilojen kuvaus näkyy värimerkin kanssa</li>
                <li>Sanahaku lisätty pääkäyttäjän näkymään</li>
              </ul>
              <br />
              <p><strong>2023.02.23</strong></p>
              <ul>
                <li>Aloitettu tilastointiosan käyttöönotto</li>
                <li>Profiili-sivulle lisätty tilastoja</li>
              </ul>
              <br />
              <p><strong>2023.02.17</strong></p>
              <ul>
                <li>Korjattu bugi lisättäessä kuvaa muokkaustilasta</li>
              </ul>
              <br />
              <p><strong>2023.02.14</strong></p>
              <ul>
                <li>
                  Lisätty tunnistus, jos kirjautumistoken vanhenee ja käyttäjä
                  on sivulla josta ei ole pääsyä tokenin uusimiseen -> käyttäjä
                  ohjautuu kirjautumiseen suoraan
                </li>
                <li>
                  Lisätty karttanäkymän sekä kohteen lisäyksessä olevan
                  karttanäkymän koordinaattien oletusasetusten määrittäminen
                </li>
                <li>
                  Nappien ulkoasua ja kokoja yhtenäistetty parempaa
                  mobiilikäyttöä varten
                </li>
                <li>
                  Lisätty toiminto, jolla kohteeseen voi kytkeä tiedotteen
                </li>
                <li>
                  Kartassa useat lähekkäin olevat kohteet klusteroituvat ja
                  klusteria klikkaamalla näkee kohteet jotka ovat "sumpussa"
                </li>
              </ul>
              <br />
              <p><strong>2023.01.02</strong></p>
              <ul>
                <li>
                  Lisätty käyttäjäylläpitoon painike SMS-kirjautumislinkin
                  lähettämiseen
                </li>
                <li>
                  Muutamia painikkeita muutettu sopimaan paremmin kapeampiin
                  näyttöihin
                </li>
                <li>
                  Lisätty toiminto napauttaa sijainti kohdalleen kohdetta
                  lisättäessä/muokattaessa
                </li>
                <li>Korjattu etusivun Tila -sarakkeen lajittelu</li>
              </ul>
              <br />
              <p><strong>2022.12.08</strong></p>
              <ul>
                <li>
                  Lisätty painike lumisade-efektin pois kytkemiseksi (ei
                  valitettavasti kytke ulkoa lumisadetta päälle/pois)
                </li>
                <li>Lisätty ylläpitoon käyttäjän tietojen muokkaus</li>
                <li>Lokien hakeminen rajattu 20 viimeisimpään</li>
                <li>
                  Huollettu kohta muutettu vaihtamaan väriä riippuen miten pitkä
                  aika edellisestä huollosta on
                </li>
                <li>
                  Lisätty tietokantaan sarakkeita tulevaa statistiikka
                  päivitystä varten
                </li>
                <li>Muutettu kirjautumisikkunaa yksinkertaisemmaksi</li>
                <li>Lisätty roolien lajittelu</li>
                <li>Lisätty Ohje-sivu</li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <aaltotausta />
  </section>
</template>

<script>
import Aaltotausta from "@/components/Aaltotausta.vue";

export default {
  name: "Ohje",
  components: {
    Aaltotausta,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
