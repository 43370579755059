<template>
<div>
<!-- <iframe v-if="this.$attrs.karttalinkki" :src="this.$attrs.karttalinkki" width="100%" height="400px" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
<!-- <div v-if="!this.$attrs.karttalinkki" class="center">Karttaa ei saatavilla</div> -->
<GmapMap
:center="{lat:this.$attrs.lat, lng:this.$attrs.lon}"
:zoom="16"
style="width: 100%; height: 500px"
ref="gmap">
<GmapPolyline :path="route" :options="{ strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 3 }"></GmapPolyline>
</GmapMap>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>