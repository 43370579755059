<template>
    <section>
    <b-loading :is-full-page="true" v-model="latausStatus" :can-cancel="true"></b-loading>
    <div class="tile is-ancestor">
    <div class="tile is-parent has-text-left">
        <div class="tile is-child box has-background-light">
          <strong>Käyttäjän {{this.$attrs.kayttaja.Nimi}} tiedot </strong><br /><br />
            <b-collapse 
            :open="false"
            class="card" 
            animation="slide" 
            aria-id="contentIdForA11y3">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3"
                    :aria-expanded="props.open">
                    <p class="card-header-title">
                        Yleiset
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                        :icon="props.open ? 'caret-up' : 'caret-down'">
                        </b-icon>
                    </a>
                </div>
            </template>

            <div class="card-content">
              <div class="flex">
              <b-field label="Nimi">
                <b-input v-model="nimi" value="nimi"></b-input>
              </b-field>

              <b-field label="Puhelin (esim. +35840123456)">
                <b-input v-model="puhelin" type="text" maxlength="40" pattern="^[+]358*[\s\./0-9]*"> </b-input>
              </b-field>

              <b-field label="Sähköposti">
                <b-input v-model="sahkoposti" type="email" maxlength="60"> </b-input>
              </b-field>
            </div>
            <b-field grouped group-multiline>
                <b-button type="is-primary is-medium" @click="paivitaTiedot" class="m-2">Tallenna</b-button>
                <b-button type="is-success is-medium" :disabled="this.$attrs.kayttaja.Aktiivinen == 1" class="m-2" @click="aktivoiTunnus">Ota tili käyttöön</b-button>
                <b-button type="is-warning is-medium" :disabled="this.$attrs.kayttaja.Aktiivinen == 0" class="m-2" @click="disabloiTunnus">Ota tili pois käytöstä</b-button>
                <b-button type="is-info is-medium" :disabled="this.$attrs.kayttaja.Aktiivinen == 0" class="m-2" @click="lahetakirjautumisToken">Lähetä SMS-kirjautumislinkki</b-button>
              </b-field>
              </div>
        </b-collapse>
        <br>
            <b-collapse 
            :open="false"
            class="card" 
            animation="slide" 
            aria-id="contentIdForA11y3">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3"
                    :aria-expanded="props.open">
                    <p class="card-header-title">
                        Roolit
                    </p>

                    <a class="card-header-icon">
                        <b-icon
                        :icon="props.open ? 'caret-up' : 'caret-down'">
                        </b-icon>
                    </a>
                </div>
            </template>

            <div class="card-content">
                  <b-table
                  v-if="kayttaja.roolit"
                  :data="kayttaja.roolit"
                  :per-page="perPage"
                  :paginated="isPaginated"
                  :pagination-simple="isPaginationSimple"
                  :pagination-position="paginationPosition"
                  :pagination-rounded="isPaginationRounded"
                  :selected.sync="valittuRooli"
                >
                  <b-table-column field="Nimi" label="Nimi">
                    <template v-slot="props">
                      {{ props.row.Rooli.RooliNimi }}
                    </template>
                  </b-table-column>
                  <b-table-column field="Lisätty" label="Lisätty">
                    <template v-slot="props">
                      {{ new Date(props.row.RooliKytketty).toLocaleDateString() }} {{ aikaMuutos(props.row.RooliKytketty) }}
                    </template>
                  </b-table-column>
                </b-table>
                <br>
            </div>
        </b-collapse>
        <br>
        <b-field grouped group-multiline>
        <b-button class="m-2" type="is-success is-medium" v-bind:kayttaja="this.$attrs.kayttaja.id"  @click="lisaaRooliModal">Lisää rooli</b-button>
        <b-button class="m-2" type="is-warning is-medium" v-if="valittuRooli" @click="poistaKayttajaRooli()">Poista valittu rooli</b-button>
        <b-button type="is-info is-medium" label="Poista valinta" icon-left="minus" class="m-2" :disabled="!valittuRooli" @click="valittuRooli = ''"></b-button>
        </b-field>
        <br>
        <b-collapse 
        :open="false"
        class="card" 
        animation="slide" 
        aria-id="contentIdForA11y3">
        <template #trigger="props">
            <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open">
                <p class="card-header-title">
                    Lokit (20 viimeisintä)
                </p>
                <a class="card-header-icon">
                    <b-icon
                        :icon="props.open ? 'caret-up' : 'caret-down'"
                        >
                    </b-icon>
                </a>
            </div>
        </template>

        <div class="card-content">
              <b-table
                v-if="kayttaja.loki"
                :data="kayttaja.loki"
                :default-sort="defaultSort"
                :default-sort-direction="defaultSortDirection"
                :per-page="perPage"
                :paginated="isPaginated"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :pagination-rounded="isPaginationRounded"
              >
                <b-table-column field="Tapahtuma" label="Tapahtuma" centered>
                  <template v-slot="props">
                    {{ props.row.Tapahtuma }}
                  </template>
                </b-table-column>
                <!-- <b-table-column field="Kohde" label="Kohde" centered>
                  <template v-slot="props">
                    {{ props.row.KohdeID }}
                  </template>
                </b-table-column> -->
                <b-table-column
                  field="Aikaleima"
                  label="Aikaleima"
                  sortable
                  centered
                >
                  <template v-slot="props">
                    {{
                      new Date(
                        props.row.Aikaleima
                      ).toLocaleDateString()
                    }}
                    {{
                      new Date(
                        props.row.Aikaleima
                      ).toLocaleTimeString()
                    }}
                  </template>
                </b-table-column>
              </b-table>
            </div>
    </b-collapse>
    <br>
    <b-collapse 
    :open="false"
    class="card" 
    animation="slide" 
    aria-id="contentIdForA11y3">
    <template #trigger="props">
        <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open">
            <p class="card-header-title">
                Tilastot
            </p>
            <a class="card-header-icon">
                <b-icon
                :icon="props.open ? 'caret-up' : 'caret-down'">
                </b-icon>
            </a>
        </div>
    </template>

    <div class="card-content">
      <div class="flex">
        <p class="is-size-5"><strong>On huoltanut kohteita</strong></p>
        <p class="is-size-5 mb-2"> {{ kayttajatilasto.maara }} kertaa</p>
        <p class="is-size-5" v-if="kayttajatilasto.paiviaedellisestahuollosta != 'NA'"><strong>On viimeksi huoltanut kohteen</strong></p>
        <p class="is-size-5 mb-2" v-if="kayttajatilasto.paiviaedellisestahuollosta != 'NA'">{{ kayttajatilasto.viimeksihuollettukohde }}, {{ kayttajatilasto.paiviaedellisestahuollosta }} päivää sitten</p>
      </div>
      </div>
</b-collapse>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import UserService from "@/services/user.service.js"
import TilastoService from "@/services/tilasto.service.js"
import YllapitoLisaaRooliKayttaja from './YllapitoLisaaRooliKayttaja.vue';

export default {
    data() {
    return {
        latausStatus: true,
        roolitcollapse: "0",
        kayttaja: '',
        kohdeoikeudet: '',
        defaultSortDirection: "desc",
        defaultSort: "Aikaleima",
        perPage: 5,
        currentPage: 1,
        isPaginated: true,
        paginationPosition: "bottom",
        isPaginationRounded: false,
        isPaginationSimple: false,
        valittuRooli: '',
        nimi: '',
        puhelin: '',
        sahkoposti: '',
        kayttajatilasto: ''
    }
    },
    mounted () {
        this.haeTiedot();
        this.haeKayttajaTilastot();
        this.nimi = this.$attrs.kayttaja.Nimi;
        if (this.$attrs.kayttaja.Puhelin) {this.puhelin = "+" + this.$attrs.kayttaja.Puhelin;}
        this.sahkoposti = this.$attrs.kayttaja.Sahkoposti;
    },
    methods: {

      aikaMuutos(aikamuutos){
        var aika = new Date(aikamuutos);
        aika = aika.getTime();
        var vahennys = (2 * 60) * 60 * 1000;
        aika = new Date(aika - vahennys)
        aika = aika.toLocaleTimeString()
        return(aika)
    },

      poistaKayttajaRooli() {
      var id = this.kayttaja.id
      var rooliid = this.valittuRooli.Rooli.RooliID

      UserService.poistaRooliKayttaja(id, rooliid)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Roolin poisto onnistui`,
            type: 'is-success'
          })
          this.haeTiedot();
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Roolin poisto epäonnistui`,
            type: 'is-success'
          })
          this.$emit('close')
        });
    },

        lisaaRooliModal() {
        this.$buefy.modal.open({
          parent: this,
          component: YllapitoLisaaRooliKayttaja,
          hasModalCard: true,
          customClass: "custom-class",
          trapFocus: true,
          events: {
            'success-response': value => {
            console.log(value)
            console.log("Rooli lisätty, haetaan API-rajapinnasta päivitetyt roolit")
            this.haeTiedot();
          }
          },
          props: {
            "valittukayttaja": this.$attrs.kayttaja.id
          },
        });
      },

      paivitaTiedot(){
        this.latausStatus = true;
        console.log("Lähetetään API-kutsu tunnuksen luomiseen")
        var data = {
          TunnusID: this.$attrs.kayttaja.id,
          Nimi: this.nimi,
          Puhelin: this.puhelin,
          Sahkoposti: this.sahkoposti,
        };
      UserService.paivitaKayttajaTiedot(data)
        .then(response => {
          console.log(response.data);
          this.haeTiedot();
          this.latausStatus = false;
          this.$buefy.toast.open({
            message: `Tietojen päivitys onnistui`,
            type: 'is-success'
          })
        })
        .catch(e => {
          console.log(e);
          this.latausStatus = false;
          this.$buefy.toast.open({
            message: `Tietojen päivitys epäonnistui`,
            type: 'is-danger'
          })
        });
    },

        haeTiedot() {
          this.latausStatus = true;
          UserService.haeKayttajaTiedot(this.$attrs.kayttaja.id)
            .then(response => {
            this.kayttaja = response.data;
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });
        },

        haeKayttajaTilastot() {
          this.latausStatus = true;
          TilastoService.kayttajatilasto(this.$attrs.kayttaja.id)
            .then(response => {
            this.kayttajatilasto = response.data;
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });
      },

        aktivoiTunnus(){
            console.log("Enabloidaan tunnus " + this.$attrs.kayttaja.id)
            UserService.enabloiKayttaja(this.$attrs.kayttaja.id)
            .then(response => {
            console.log(response)
            })
            .catch(e => {
            console.log(e);
            });
        },

        disabloiTunnus(){
            console.log("Disabloidaan tunnus " + this.$attrs.kayttaja.id)
            UserService.disabloiKayttaja(this.$attrs.kayttaja.id)
            .then(response => {
            console.log(response)
            })
            .catch(e => {
            console.log(e);
            });
        },
        lahetakirjautumisToken(){
          var data = {
          puhelin: this.$attrs.kayttaja.Puhelin
        };
            console.log("Lähetetään SMS-kirjautuminen ")
            UserService.kirjautumisTokenGenerated(data)
            .then(response => {
            console.log(response)
            this.$buefy.toast.open({
            message: `SMS-kirjautumislinkki lähetetty`,
            type: 'is-success'
          })
            })
            .catch(e => {
            console.log(e);
            this.$buefy.toast.open({
            message: `SMS-kirjautumislinkkia ei voitu lähettää`,
            type: 'is-danger'
          })
            });
        },
    }
}
</script>

<style>

</style>