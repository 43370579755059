<template>
<div>
    <b-navbar shadow class="has-text-left">
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img
                    src="../assets/logo.png"
                >
            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <b-button label="Listanäkymä" icon-left="list"></b-button>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/karttanakyma' }">
                <b-button label="Karttanäkymä" icon-left="map"></b-button>
            </b-navbar-item>
            <b-navbar-item>
            <b-field>
                <b-switch 
                    v-model="lumisade">
                    ❄️
                </b-switch>
            </b-field>
        </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item tag="router-link" :to="{ path: '/kohde/lisaa' }" v-if="currentUser">
                <b-button v-if="onkoPaakayttaja() || onkoMuokkaaja()" label="Lisää uusi kohde" icon-left="plus-square"></b-button>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/kohde/valinta' }" v-if="currentUser">
                <b-button label="Päivitä kohteen tietoja" icon-left="pen-square"></b-button>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/profiili' }" v-if="currentUser">
                <b-button label="Profiili" icon-left="user-circle"></b-button>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/ohje' }" v-if="currentUser">
                <b-button label="Ohje" icon-left="book"></b-button>
            </b-navbar-item>
            <b-navbar-item>
            <b-dropdown aria-role="list" v-if="onkoPaakayttaja() || onkoTunnustaja() || onkoRoolittaja()">

                <template #trigger>
                    <b-button
                        label="Ylläpito"
                        type="is-primary"
                        icon-left="user-shield"
                        icon-right="caret-down" />
                </template>
        
                <b-dropdown-item aria-role="listitem" v-if="onkoPaakayttaja() || onkoTunnustaja()">
                    <b-navbar-item tag="router-link" :to="{ path: '/yllapito/kayttaja' }">
                    <div class="media">
                        <b-icon class="media-left" icon="users"></b-icon>
                        <div class="media-content has-text-left">
                            <h3>Käyttäjät</h3>
                            <small>Käyttäjien hallinta</small>
                        </div>
                    </div>
                    </b-navbar-item>
                </b-dropdown-item>
        
                <b-dropdown-item aria-role="listitem" tag="router-link" :to="{ path: '/yllapito' }" v-if="onkoPaakayttaja() || onkoRoolittaja()">
                    <b-navbar-item tag="router-link" :to="{ path: '/yllapito/rooli' }">
                    <div class="media">
                        <b-icon class="media-left" icon="users"></b-icon>
                        <div class="media-content has-text-left">
                            <h3>Roolit</h3>
                            <small>Roolien hallinta</small>
                        </div>
                    </div>
                </b-navbar-item>
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem" tag="router-link" :to="{ path: '/yllapito' }" v-if="onkoPaakayttaja()">
                    <b-navbar-item tag="router-link" :to="{ path: '/yllapito/tyyppi' }">
                    <div class="media">
                        <b-icon class="media-left" icon="book"></b-icon>
                        <div class="media-content has-text-left">
                            <h3>Tyypit</h3>
                            <small>Tyyppien hallinta</small>
                        </div>
                    </div>
                </b-navbar-item>
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem" tag="router-link" :to="{ path: '/yllapito' }" v-if="onkoPaakayttaja()">
                    <b-navbar-item tag="router-link" :to="{ path: '/yllapito/jarjestelma' }">
                    <div class="media">
                        <b-icon class="media-left" icon="cog"></b-icon>
                        <div class="media-content has-text-left">
                            <h3>Järjestelmä</h3>
                            <small>Asetusten hallinta</small>
                        </div>
                    </div>
                </b-navbar-item>
                </b-dropdown-item>
            </b-dropdown>
        </b-navbar-item>
        <b-navbar-item tag="div" v-if="!currentUser">
            <div class="buttons">
                <router-link to="/kirjaudu" custom 
                    v-slot="{ href, navigate }">
                <a class="button is-light" :href="href" @click="navigate" v-if="!currentUser">
                    Kirjaudu
                </a>
                </router-link>
            </div>
        </b-navbar-item>
        <b-navbar-item tag="div" v-if="currentUser">
            <div class="buttons">
                <a class="button is-light" href @click.prevent="logOut">
                    Kirjaudu ulos
                </a>
            </div>
        </b-navbar-item>
        </template>
    </b-navbar>
</div>
</template>

<script>

export default {
    data() {
    return {

    }
},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    lumisade: {
    get: function () {
        return this.$store.state.lumisade
    },
    set: function () {
        this.$store.commit("AsetaLumisade");
    }
  },
  },
  methods: {
    onkoMuokkaaja(){
        if (this.currentUser) {
        for(var i=0; i<this.currentUser.roolit.length; i++) {
            console.log(this.currentUser.roolit[i].Rooli)
            if(this.currentUser.roolit[i].Rooli.RooliNimi == 'Muokkaaja') {
            console.log("Muokkaajan oikeudet käytössä")
            return true
        } 
    }
    }},

    onkoRoolittaja(){
        if (this.currentUser) {
        for(var i=0; i<this.currentUser.roolit.length; i++) {
            console.log(this.currentUser.roolit[i].Rooli)
            if(this.currentUser.roolit[i].Rooli.RooliNimi == 'Roolittaja') {
            console.log("Muokkaajan oikeudet käytössä")
            return true
        } 
    }
    }},

    onkoTunnustaja(){
        if (this.currentUser) {
        for(var i=0; i<this.currentUser.roolit.length; i++) {
            console.log(this.currentUser.roolit[i].Rooli)
            if(this.currentUser.roolit[i].Rooli.RooliNimi == 'Tunnustaja') {
            console.log("Muokkaajan oikeudet käytössä")
            return true
        } 
    }
    }},
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/kirjaudu');
    },
    onkoPaakayttaja() {
        if (this.currentUser) {
        for(var i=0; i<this.currentUser.roolit.length; i++) {
            console.log(this.currentUser.roolit[i].Rooli)
            if(this.currentUser.roolit[i].Rooli.RooliNimi == 'Pääkäyttäjä') {
            console.log("Pääkäyttäjän oikeudet käytössä")
            return true
        }  
    }
    }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
