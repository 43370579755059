<template>

    <b-field group v-if="this.$attrs.huollettu !== null">
    <b-button @click="klikkaaMua" expanded type="is-info is-medium">Huollettu: {{ new Date(this.$attrs.huollettu).toLocaleDateString() }} {{ new Date(this.$attrs.huollettu).toLocaleTimeString() }}</b-button>
    <b-button @click="klikkaaMua" v-if="!this.$attrs.huollettu" expanded type="is-warning is-medium">Huollettu: Ei merkittyjä huoltoja</b-button>
    </b-field>

</template>

<script>
    export default {
        methods: {
            klikkaaMua() {
                    this.$buefy.toast.open({
                    message: `Tarkka aikaleima: ` + this.$attrs.huollettu,
                    position: 'is-bottom'        
                })
            }
        }
    }
</script>

<style>

</style>