<template>
    <div class="modal-card" >
      <header class="modal-card-head">
        <p class="modal-card-title">Lisää oikeus rooliin: {{ this.$attrs.RooliNimi }}</p>
        <button type="button" class="delete" @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <b-field>
          <b-select placeholder="Valitse kohde" size="is-medium" v-model="kohdevalinta" expanded>
            <option v-for="kohde in kohteet" :key=kohde.KohdeID :value=kohde.KohdeID>{{ kohde.Nimi }} | {{kohde.Tyyppi}}</option>
          </b-select>
        </b-field>
<p>Sallitaan:</p>
<br>
  <b-field>
    <b-checkbox-button v-model="voiHuoltaa"
    native-value="voihuoltaa"
    expanded
    type="is-success">
    <b-icon v-if="voiHuoltaa.length == 1" icon="check"></b-icon>
    <b-icon v-if="voiHuoltaa.length == 0" icon="minus"></b-icon>
    <span>Huoltaminen</span>
    </b-checkbox-button>
  </b-field>
<b-field>
    <b-checkbox-button v-model="voiMuokata"
    native-value="voimuokata"
    expanded
    type="is-success">
    <b-icon v-if="voiMuokata.length == 1" icon="check"></b-icon>
    <b-icon v-if="voiMuokata.length == 0" icon="minus"></b-icon>
    <span>Muokkaaminen</span>
</b-checkbox-button>
</b-field>

      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$emit('close')">Sulje</button>
        <button class="button is-primary" @click="lisaaOikeus()">Tallenna</button>
      </footer>
    </div>
  </template>
  
  <script>
  import UserService from "@/services/user.service.js"
  import KohdeDataService from "@/services/kohde.service.js";
  
  export default {
    name: "YllapitoLisaaOikeusModal",
    data() {
      return {
        latausStatus: true,
        kohdevalinta: "",
        kohteet: [],
        voiHuoltaa: [],
        voiMuokata: [],
      };
    },
    computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
    mounted() {
      this.haeKohdeLuettelo()
    },
    methods: {

      lisaaOikeus() {
      var huolto = 0
      var muokkaus = 0
      if (this.voiHuoltaa.length == 1) {huolto = 1}
      if (this.voiMuokata.length == 1) {muokkaus = 1}

      var data = {
        KohdeID: this.kohdevalinta,
        RooliID: this.$attrs.RooliID,
        VoiHuoltaa: huolto,
        VoiMuokata: muokkaus
      };

      UserService.lisaaOikeus(data)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Oikeuden lisäys onnistui`,
            type: 'is-success'
          })
          this.$emit('success-response', "true")
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Oikeuden lisäys epäonnistui`,
            type: 'is-danger'
          })
          this.$emit('close')
        });
    },

      haeKohdeLuettelo() {
      KohdeDataService.haeKohteetHuoltoOikeus(this.currentUser.id)
        .then(response => {
          let kohteethuoltotemp = response.data;
          kohteethuoltotemp = kohteethuoltotemp.sort((a, b) => (a.Nimi > b.Nimi) ? 1 : -1)
          this.kohteet = kohteethuoltotemp;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    
    },
    }
  };
  </script>