<template>
  <section class="hero has-background-info">
  <div class="hero-body">
  <h1 class="title has-text-white">Hei <strong>{{currentUser.nimi}}</strong> 👋</h1>
  <div class="tile is-ancestor">
    <div class="tile is-4 is-vertical is-parent">
      <div class="tile is-child box has-background-light">
        <p class="is-size-5"><strong>Kirjauduit viimeksi</strong></p>
        <p class="mb-2">{{ new Date(currentUser.viimeisinkirjautuminen).toLocaleDateString() }} {{ kirjautumisAika() }}</p>
        <p class="is-size-5"><strong>Kirjautuminen voimassa</strong></p>
        <p class="mb-2">{{ new Date(currentUser.jwttokenVanhenee).toLocaleDateString() }} {{ tokenvanhenemisAika() }}</p>
        <h2 hidden><strong>Token:</strong> <br>{{currentUser.accessToken.substring(0, 20)}} ... {{currentUser.accessToken.substr(currentUser.accessToken.length - 20)}}</h2><br hidden>
        <p class="is-size-5"><strong>Roolit</strong></p>
        <ul class="mb-5">
            <li v-for="(rooli, index) in kayttaja.roolit" :key="index">
               {{ rooli.Rooli.RooliNimi }}
            </li>
        </ul>
        <b-field group>
        <b-button type="is-large" label="Päivitä omia tietoja" expanded icon-left="id-card" @click="avaaOmatTiedotModal"></b-button>
      </b-field>
      <b-field group>
        <b-button type="is-large" label="Vaihda salasana" expanded icon-left="key" @click="avaaSalasanaModal"></b-button>
      </b-field>
        </div>
        <div class="tile is-child box has-background-light">
          <p class="is-size-5"><strong>Olet huoltanut kohteita</strong></p>
          <p class="is-size-5 mb-2"> {{ kayttajatilasto.maara }} kertaa</p>
          <p class="is-size-5" v-if="kayttajatilasto.paiviaedellisestahuollosta != 'NA'"><strong>Olet viimeksi huoltanut kohteen</strong></p>
          <p class="is-size-5 mb-2" v-if="kayttajatilasto.paiviaedellisestahuollosta != 'NA'">{{ kayttajatilasto.viimeksihuollettukohde }}, {{ kayttajatilasto.paiviaedellisestahuollosta }} päivää sitten</p>
        </div>
    </div>
    <div class="tile is-parent">
      <div class="tile is-child box has-background-light">
        <p class="is-size-5 mb-2"><strong>Mitä olet viimeksi puuhannut</strong></p>
            <div class="box">
              <b-table
                :data="kayttaja.loki"
                :default-sort="defaultSort"
                :default-sort-direction="defaultSortDirection"
                :per-page="perPage"
                :paginated="isPaginated"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :pagination-rounded="isPaginationRounded"
              >
                <b-table-column field="Tapahtuma" label="Tapahtuma" centered>
                  <template v-slot="props">
                    {{ props.row.Tapahtuma }}
                  </template>
                </b-table-column>
                <!-- <b-table-column field="Kohde" label="Kohde" centered>
                  <template v-slot="props">
                    {{ props.row.KohdeID }}
                  </template>
                </b-table-column> -->
                <b-table-column
                  field="Aikaleima"
                  label="Aikaleima"
                  sortable
                  centered
                >
                  <template v-slot="props">
                    {{
                      new Date(
                        props.row.Aikaleima
                      ).toLocaleDateString()
                    }}
                    {{
                      new Date(
                        props.row.Aikaleima
                      ).toLocaleTimeString()
                    }}
                  </template>
                </b-table-column>
              </b-table>
            </div>
      </div>
    </div>
  </div> 
    </div>
  <aaltotausta/>
  </section>
</template>

<script>
import UserService from "@/services/user.service.js"
import TilastoService from "@/services/tilasto.service.js"
import Aaltotausta from '@/components/Aaltotausta.vue'
import ProfiiliOmatTiedot from '@/components/ProfiiliOmatTiedot.vue'
import ProfiiliSalasana from '@/components/ProfiiliSalasana.vue'

export default {
  name: 'Profiili',
  components: {
    Aaltotausta
  },
  data() {
    return {
      defaultSortDirection: "desc",
      defaultSort: "Aikaleima",
      perPage: 10,
      currentPage: 1,
      isPaginated: true,
      paginationPosition: "bottom",
      isPaginationRounded: false,
      isPaginationSimple: false,
      kayttaja: '',
      kayttajatilasto: ''
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    kirjautumisAika(){
        var aika = new Date(this.currentUser.viimeisinkirjautuminen);
        aika = aika.getTime();
        var vahennys = (2 * 60) * 60 * 1000;
        aika = new Date(aika - vahennys)
        aika = aika.toLocaleTimeString()
        return(aika)
    },
    tokenvanhenemisAika(){
        var aika = new Date(this.currentUser.jwttokenVanhenee);
        aika = aika.getTime();
        var vahennys = (2 * 60) * 60 * 1000;
        aika = new Date(aika - vahennys)
        aika = aika.toLocaleTimeString()
        return(aika)
    },
    avaaOmatTiedotModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ProfiiliOmatTiedot,
        hasModalCard: true,
        customClass: "custom-class",
        trapFocus: true
      });
    },

    haeTiedot() {
          this.latausStatus = true;
          UserService.haeKayttajaTiedot(this.currentUser.id)
            .then(response => {
            this.kayttaja = response.data;
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });
      },

      haeKayttajaTilastot() {
          this.latausStatus = true;
          TilastoService.kayttajatilasto(this.currentUser.id)
            .then(response => {
            this.kayttajatilasto = response.data;
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });
      },

    avaaSalasanaModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ProfiiliSalasana,
        hasModalCard: true,
        customClass: "custom-class",
        trapFocus: true
      });
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/kirjaudu');
    }
    
    if (this.currentUser) {
      this.haeTiedot();
      this.haeKayttajaTilastot();
    }
  }
};
</script>