<template>
    <section>
        <b-loading :is-full-page="true" v-model="latausStatus" :can-cancel="true"></b-loading>
        <div class="tile is-ancestor">
        <div class="tile is-parent has-text-left">
            <div class="tile is-child box has-background-light">
                <b-collapse 
                :open="true"
                class="card" 
                animation="slide" 
                aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">
                        <p class="card-header-title">
                            Oletussijainti
                        </p>
                        <a class="card-header-icon">
                            <b-icon
                            :icon="props.open ? 'caret-up' : 'caret-down'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                  <div class="flex">
                        <GmapMap
                        :center="{lat:oletuskoordlat, lng:oletuskoordlon}"
                        :zoom=oletuskarttazoom
                        :clickable="true"
                        @click="getMarkerPosition($event)"
                        style="width: 100%; height: 400px"
                        ref="gmap">
                        <GmapMarker :position="{lat:oletuskoordlat, lng:oletuskoordlon}" :draggable="true" @dragend="getMarkerPosition($event)"/>
                        </GmapMap>
                        <b-field group class="mt-2" label="Oletus zoomaus">
                            <b-slider v-model="oletuskarttazoom" :min="1" :max="20"></b-slider>
                        </b-field>
                        <b-field group class="mt-2">
                            <b-button type="is-success is-large" :loading="lataaKoordinaattiNappi" @click="tallennaOletusKoordinaatit()" label="Tallenna" icon-left="save"></b-button>
                        </b-field>
                </div>
            </div>
            </b-collapse>
            <b-collapse 
            :open="false"
            class="card mt-2" 
            animation="slide" 
            aria-id="contentIdForA11y3">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3"
                    :aria-expanded="props.open">
                    <p class="card-header-title">
                        Tiedotepohjat
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                        :icon="props.open ? 'caret-up' : 'caret-down'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
              <div class="flex">
                    <b-field group class="mt-2">
                        <b-button type="is-success is-large" label="Lisää" icon-left="save"></b-button>
                        <b-button type="is-danger is-large ml-2" label="Poista" icon-left="save"></b-button>
                    </b-field>
            </div>
        </div>
        </b-collapse>
            </div>
        </div>
        </div>
    </section>
</template>

<script>
import JarjestelmaService from "@/services/jarjestelma.service.js"

export default {
  name: "YllapitoJarjestelma",
  data() {
      return {
          jarjestelma: "",
          oletuskarttazoom: 0,
          latausStatus: false,
          oletuskoordlat: 0.0,
          oletuskoordlon: 0.0,
          lataaKoordinaattiNappi: false,
      }
  },
mounted() {

    this.latausStatus = true;
    JarjestelmaService.jarjestelmaKoordinaatti()
    .then(response => {
    const koordinaattiresp = response.data;
    this.oletuskoordlat = parseFloat(koordinaattiresp.KoordinaattiLAT)
    this.oletuskoordlon = parseFloat(koordinaattiresp.KoordinaattiLON)
    this.oletuskarttazoom = parseInt(koordinaattiresp.KarttaZoom)
    this.latausStatus = false;
    })
    .catch(e => {
    console.log(e);
    this.latausStatus = false;
    });

    this.haeJarjestelmaTiedot()
},
methods: {
    haeJarjestelmaTiedot() {
          JarjestelmaService.jarjestelmaAsetukset()
            .then(response => {
            this.jarjestelma = response.data;          
            this.latausStatus = false;
            })
            .catch(e => {
            console.log(e);
            this.latausStatus = false;
            });
        },

    tallennaOletusKoordinaatit() {
      var data = {
        KoordinaattiLAT: this.oletuskoordlat,
        KoordinaattiLON: this.oletuskoordlon,
        KarttaZoom: this.oletuskarttazoom,
      };

      JarjestelmaService.jarjestelmaKoordinaattiPaivita(data)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Oletuskoordinaattien päivitys onnistui`,
            type: 'is-success'
          })
          this.$emit('success-response', "true")
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Oletuskoordinaattien päivitys epäonnistui`,
            type: 'is-danger'
          })
          this.$emit('close')
        });
    },

    getMarkerPosition (event) {
            this.oletuskoordlat = event.latLng.lat(),
            this.oletuskoordlon = event.latLng.lng()
            this.marker = {lat: event.latLng.lat(), lng:event.latLng.lng()}
      },
}
}
</script>

<style>

</style>