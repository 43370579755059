<template>
  <section>
    <div class="container">
      <div class="notification has-background-light">
        <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
          :mobile-mode="mobileMode"
        >
          <b-step-item
            step="1"
            label="Perustiedot"
            :clickable="isStepsClickable"
          >
            <h1 class="title has-text-centered">Perustiedot</h1>
            <div class="block">
            <b-field label="Nimi" :label-position="labelPosition">
                <b-input placeholder="Kirjoita nimi" size="is-medium" v-model="KohdeNimi" required></b-input>
            </b-field>

            <b-field label="Tyyppi">
              <b-select placeholder="Valitse tyyppi" size="is-medium" v-model="KohdeTyyppi" expanded required>
                <option v-for="tyyppi in tyypit" :key=tyyppi.TyyppiID :value=tyyppi.TyyppiNimi>{{ tyyppi.TyyppiNimi }}</option>
              </b-select>
            </b-field>

            <b-field label="Esittely">
              <vue-editor v-model="KohdeEsittely" :editorToolbar="customToolbar" style="background-color:white;"></vue-editor>
            </b-field>
            <!-- <b-field label="Esittely">
              <b-input maxlength="400" type="textarea" size="is-medium" v-model="KohdeEsittely"></b-input>
            </b-field> -->

            <!-- <b-field label="Karttalinkki" :label-position="labelPosition">
                  <b-input placeholder="Anna osoite upotettavalle kartalle" size="is-medium" type="url" v-on:blur="karttalinkkiBlur()" v-model="KohdeKarttalinkki"></b-input>
            </b-field>

            <p class="has-text-left" v-if="KarttalinkkiBlurInfo == 1">Esikatselu:</p>
            <iframe v-if="KarttalinkkiBlurInfo == 1" :src="KohdeKarttalinkki" width="100%" height="400px" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
            <b-field label="Sijainti">
            <GmapMap
            :center="{lat:KohdeKoordLAT, lng:KohdeKoordLON}"
            :zoom="16"
            :clickable="true"
            @click="getMarkerPosition($event)"
            style="width: 100%; height: 500px"
            ref="gmap">
            <GmapMarker :position="{lat:KohdeKoordLAT, lng:KohdeKoordLON}" :draggable="true" @dragend="getMarkerPosition($event)"/>
            </GmapMap>
          </b-field>
          <b-button type="is-success" @click="haeGPSSijainti">Keskitä omaan GPS sijaintiin</b-button>
            <!-- <br> -->

            <!-- <b-field label="Koordinaatti LAT" :label-position="labelPosition">
                <b-input type="number" step="any" placeholder="65.230403" v-model="KohdeKoordLAT" size="is-medium"></b-input>
            </b-field>

            <b-field label="Koordinaatti LON" :label-position="labelPosition">
                <b-input type="number" step="any" placeholder="23.2334" v-model="KohdeKoordLON" size="is-medium"></b-input>
            </b-field> -->

            </div>
          </b-step-item>

          <b-step-item
            step="2"
            label="Lisätiedot"
            :clickable="isStepsClickable"
            :type="{ 'is-success': isProfileSuccess }"
          >
            <h1 class="title has-text-centered">Lisätiedot</h1>

            <b-field label="Onko kohde kaupungin/kunnan ylläpidossa?">
              <b-switch size="is-medium" v-model="KohdeOmaYllapito"
                  true-value="Kyllä"
                  false-value="Ei">
                  {{ KohdeOmaYllapito }}
              </b-switch>
          </b-field>

            <b-field label="Onko kohteessa valaistus?">
                <b-switch size="is-medium" v-model="KohdeValaistus"
                    true-value="Kyllä"
                    false-value="Ei">
                    {{ KohdeValaistus }}
                </b-switch>
            </b-field>

            <b-field label="Tietoja valaistuksesta" v-if="KohdeValaistus === 'Kyllä'">
                <b-input maxlength="300" type="textarea" v-model="KohdeValaistusInfo" 
                placeholder="Valot ajastuksella/hämäräkytkimellä klo. 21.00 - 23.00"
                ></b-input>
            </b-field>

            <b-field label="Sallitaanko kommentointi?">
                <b-switch size="is-medium" v-model="KohdeKommentointi"
                    true-value="Kyllä"
                    false-value="Ei">
                    {{ KohdeKommentointi }}
                </b-switch>
            </b-field>

            <b-field label="Muutetaanko kohteen tila tuntiperusteisesti?">
                <b-switch size="is-medium" v-model="KohdeAutoTila"
                    true-value="Kyllä"
                    false-value="Ei">
                    {{ KohdeAutoTila }}
                </b-switch>
            </b-field>
            <section v-if="KohdeAutoTila === 'Kyllä'">

             <b-field label="Valitse tilamuutoksen keskimmäinen ajanjakso">
                <b-slider v-model="kohtalainentunnit" type="is-warning" :min="0" :max="48" :step="1" ticks>
                </b-slider>
            </b-field>

            <b-field label="Hyvä tila">
                <b-slider v-model="hyvatunnit" type="is-success" :min="0" :max="48" :step="1" ticks disabled indicator>
                </b-slider>
            </b-field>

            <b-field label="Kohtalainen tila">
                <b-slider v-model="kohtalainentunnit" type="is-warning" :min="0" :max="48" :step="1" ticks disabled indicator>
                </b-slider>
            </b-field>

            <b-field label="Huono tila">
                <b-slider v-model="huonotunnit" type="is-danger" :min="0" :max="48" :step="1" ticks disabled indicator>
                </b-slider>
            </b-field>
            </section>
          </b-step-item>

          <b-step-item
            step="3"
            label="Kuvat"
            :clickable="isStepsClickable"
            :type="{ 'is-success': isProfileSuccess }"
          >
            <h1 class="title has-text-centered">Kuvat</h1>
            <section>
              <b-field>
                  <b-upload v-model="ladatutKuvat"
                      v-on:change="handleFileUploads()"
                      multiple
                      drag-drop
                      expanded
                      rounded
                      accept=".jpg" required validationMessage="Valitse kuvat"
                      >
                      <section class="section">
                          <div class="content has-text-centered" style="background-color:transparent;">
                              <p>
                                  <b-icon
                                      icon="upload"
                                      size="is-large">
                                  </b-icon>
                              </p>
                              <p>Raahaa lisättävät kuvat tähän tai klikkaa ladataksesi kuvat</p>
                          </div>
                      </section>
                  </b-upload>
              </b-field>
      
              <div class="tags">
                <span v-for="(file, index) in ladatutKuvat"
                    :key="index"
                    :class="{ 'tag is-danger valkyta': file.size > 1024 * 1024}"
                    class="tag is-primary" >
                    <strong v-if="file.size > 1024 * 1024">Tiedosto on liian suuri (raja 2MB) : </strong>
                    {{file.name}}
                    <button class="delete"
                        type="button"
                        size="is-medium"
                        @click="deleteDropFile(index)">
                    </button>
                </span>
            </div>
              <b-table
              v-if="kuvat.Kuvat"
              :data="kuvat.Kuvat"
              :checked-rows.sync="valitutKuvat"
              checkbox-position="left"
              striped
              checkable
            >
            <b-table-column field="Pikkukuva" label="Pikkukuva" centered>
              <template v-slot="props">
                <b-image
                :src="haeKuva(props.row.KuvaOID)"
                ratio="601by235"
            ></b-image>
              </template>
            </b-table-column>
              <b-table-column field="Lisätty" label="Lisätty" centered>
                <template v-slot="props">
                  {{ new Date(props.row.Aikaleima).toLocaleDateString() }} {{ aikaMuutos(props.row.Aikaleima) }}
                </template>
              </b-table-column>
            </b-table>
            <br>
            <b-button type="is-danger" v-if="valitutKuvat.length != 0" @click="poistaValitutKuvat()">Poista valitut kuvat</b-button>
          </section>
          </b-step-item>

          <b-step-item
            step="4"
            label="Yhteenveto"
            :clickable="isStepsClickable"
            :type="{ 'is-success': isProfileSuccess }"
          >
            <h1 class="title has-text-centered">Yhteenveto</h1>
            <table class="table is-fullwidth">
              <tr>
                <th>Nimi</th>
                <th>{{KohdeNimi}}</th>
              </tr>
              <tr>
                <th>Tyyppi</th>
                <th>{{KohdeTyyppi}}</th>
              </tr>
              <!-- <tr>
                <th>Karttalinkki</th>
                <th>{{KohdeKarttalinkki}}</th>
              </tr>   -->
              <tr>
                <th>Koordinaatti</th>
                <th>{{KohdeKoordLAT}} {{KohdeKoordLON}}</th>
              </tr>  
              <tr>
                <th>Esittely</th>
                <th><div v-html="KohdeEsittely"> </div></th>
              </tr>  
              <tr>
                <th>Kaupungin / kunnan ylläpidossa</th>
                <th>{{KohdeOmaYllapito}}</th>
              </tr> 
              <tr>
                <th>Valaistus</th>
                <th>{{KohdeValaistus}}</th>
              </tr> 
              <tr v-if="KohdeValaistus === 'Kyllä'">
                <th>Valaistusinfo</th>
                <th>{{KohdeValaistusInfo}}</th>
              </tr> 
              <tr>
                <th>Kommentointi</th>
                <th>{{KohdeKommentointi}}</th>
              </tr> 
              <tr>
                <th>Automaattinen tilamuutos</th>
                <th>{{KohdeAutoTila}}</th>
              </tr> 
              <tr v-if="KohdeAutoTila === 'Kyllä'">
                <th>Hyvä-tilan ajanjakso</th>
                <th>{{hyvatunnit}}</th>
              </tr> 
              <tr v-if="KohdeAutoTila === 'Kyllä'">
                <th>Kohtalainen-tilan ajanjakso</th>
                <th>{{kohtalainentunnit}}</th>
              </tr> 
              <tr v-if="KohdeAutoTila === 'Kyllä'">
                <th>Huono-tilan ajanjakso</th>
                <th>{{huonotunnit}}</th>
              </tr> 
            </table>
            
          <b-button size="is-medium" @click="lisaaKohde" type="is-success" v-if="KohdeNimi !== ''">Tallenna muutokset</b-button>
          <b-notification
            v-if="KohdeNimi === ''"
            type="is-danger"
            aria-close-label="Sulje ilmoitus"
            role="alert">
            Kohteelta puuttuu nimi
          </b-notification>

          </b-step-item>
        </b-steps>
      </div>
    </div>
  </section>
</template>

<script>
import KuvaService from "@/services/kuva.service.js"
import asetukset from '@/asetukset.js';
import KohdeDataService from "@/services/kohde.service.js";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      kohde: "",
      activeStep: 0,
      radioButton: '',
      KarttalinkkiBlurInfo: '0',
      kohtalainentunnitmin: '',
      kohtalainentunnitmax: '',
      hyvatunnit: [0,6],
      kohtalainentunnit: [6,8],
      huonotunnit: [8,48],
      KohdeNimi: '',
      KohdeKarttalinkki: '',
      KohdeKoordLAT: '',
      KohdeKoordLON: '',
      KohdeEsittely : '',
      KohdeTyyppi: "",
      KohdeValaistus: 'Ei',
      KohdeOmaYllapito: 'Ei',
      KohdeValaistusInfo: '',
      KohdeKommentointi: 'Ei',
      KohdeAutoTila: 'Ei',
      test: '',
      kuvat: '',
      valitutKuvat: [],
      ladatutKuvat: [],

      tyypit: [],
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      isSwitched: false,
      hasNavigation: true,
      isProfileSuccess: true,
      lahetetty: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ 'align': [] }],
        ['link'],
        ['clean'],
      ],

      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
    };
  }, 
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  watch: {
      'kohtalainentunnit': function(){
      this.kohtalainentunnitmin = this.kohtalainentunnit[0];
      this.kohtalainentunnitmax = this.kohtalainentunnit[1];
      this.hyvatunnit = ['0',this.kohtalainentunnitmin]
      this.huonotunnit = [this.kohtalainentunnitmax, '48']
      }
  },
  methods: {

    poistaValitutKuvat(){

    KuvaService.poistakuvat(this.valitutKuvat)
    .then(response => {
      console.log(response.data);
      this.valitutKuvat = [];
      this.haeKohteenKuvat();
      this.$buefy.toast.open({
            message: "Kuvien poisto onnistui",
            type: 'is-success'
        })
    })
    .catch(e => {
      console.log(e);
      this.$buefy.toast.open({
            message: "Kuvien poisto epäonnistui",
            type: 'is-danger'
        })
    });

    },

    kuvienLataus(){

    let formData = new FormData();
    this.ladatutKuvat.forEach((image, i) => formData.append('images[' + i + ']', image));
    KuvaService.lahetakuvat(formData, this.kohde.KohdeID)
    .then(response => {
      console.log(response.data);
      this.ladatutKuvat = []
    })
    .catch(e => {
      console.log(e);
    });

    },

    handleFilesUpload(){
    this.ladatutKuvat = this.$refs.files.ladatutKuvat;
    },

    deleteDropFile(index) {
                this.ladatutKuvat.splice(index, 1)
     },

    haeKohteenKuvat() {
      KohdeDataService.haeKohdeKuvat(this.$route.params.id)
        .then(response => {
        this.kuvat = response.data;
        this.latausStatus = false;
        })
        .catch(e => {
        console.log(e);
        this.latausStatus = false;
        });
    },

    aikaMuutos(aikamuutos){
        var aika = new Date(aikamuutos);
        aika = aika.getTime();
        var vahennys = (2 * 60) * 60 * 1000;
        aika = new Date(aika - vahennys)
        aika = aika.toLocaleTimeString()
        return(aika)
    },

    haeKuva(id) {
            let kuva = asetukset.apistatic + '/' + id + '.jpg'
            return kuva
        },

    haeGPSSijainti() {
      navigator.geolocation.getCurrentPosition(pos => {
      this.gettingLocation = false;
      this.location = pos;
      this.KohdeKoordLAT = this.location.coords.latitude
      this.KohdeKoordLON = this.location.coords.longitude
      this.marker = {lat:this.location.coords.latitude, lng:this.location.coords.longitude}
    }, err => {
      this.gettingLocation = false;
      this.errorStr = err.message;
    })
    },

    getMarkerPosition (event) {
            this.KohdeKoordLAT = event.latLng.lat(),
            this.KohdeKoordLON = event.latLng.lng()
            this.marker = {lat: event.latLng.lat(), lng:event.latLng.lng()}
      },

    karttalinkkiBlur() {
      if (this.KohdeKarttalinkki) {
        this.KarttalinkkiBlurInfo = 1;
        }
    },

      kohdeMuokattuModal() {
          this.$buefy.dialog.alert({
              title: 'Ilmoitus',
              message: 'Kohteen muutokset tallennettu 👍',
              confirmText: 'Selvä pyy!',
              onConfirm: () => this.$router.push('/kohde/' + this.$route.params.id)
          })
      },

      kohdeEiMuokattu() {
          this.$buefy.dialog.alert({
              title: 'Ilmoitus',
              message: 'Kohteen muokkaus ei onnistunut 🥲',
              confirmText: 'Yritetään myöhemmin uudelleen'
          })
      },

    haeTyyppiValinnat() {
      KohdeDataService.haeTyypit()
        .then(response => {
          this.tyypit = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    lisaaKohde() {

      if (this.ladatutKuvat) {
        this.kuvienLataus();
      }

      var data = {
        TunnusID: this.currentUser.id,
        KohdeID: this.$route.params.id,
        KohdeNimi: this.KohdeNimi,
        KohdeTyyppi: this.KohdeTyyppi,
        KohdeKarttalinkki: this.KohdeKarttalinkki,
        KohdeEsittely: this.KohdeEsittely,
        KohdeOmaYllapito: this.KohdeOmaYllapito,
        KohdeValaistus: this.KohdeValaistus,
        KohdeValaistusInfo: this.KohdeValaistusInfo,
        KohdeKommentointi: this.KohdeKommentointi,
        KohdeAutoTila: this.KohdeAutoTila,
        KohdeAutoTilaMin: this.kohtalainentunnitmin,
        KohdeAutoTilaMax: this.kohtalainentunnitmax,
        KohdeKoordinaattiLAT: this.KohdeKoordLAT,
        KohdeKoordinaattiLON: this.KohdeKoordLON
      };

      KohdeDataService.muokkaaKohde(data)
        .then(response => {
          console.log(response.data);
          this.lahetetty = true;
          this.kohdeMuokattuModal();
        })
        .catch(e => {
          console.log(e);
          this.kohdeEiMuokattu();
        });
    }
  },
  mounted() {
    this.haeTyyppiValinnat();
    this.haeKohteenKuvat();


    KohdeDataService.haeKohde(this.$route.params.id)
    .then(response => {
      //console.log(response.data);
      this.kohde = response.data;
      this.KohdeNimi = this.kohde.Nimi;
      this.KohdeTyyppi = this.kohde.Tyyppi;
      this.KohdeKarttalinkki = this.kohde.Karttalinkki;
      this.KohdeEsittely = this.kohde.Esittely;
      this.KohdeKoordLAT = this.kohde.KoordinaattiLAT;
      this.KohdeKoordLON = this.kohde.KoordinaattiLON;
      if (this.kohde.OmaYllapito == 1)
      {
        this.KohdeOmaYllapito = 'Kyllä'
      }
      if (this.kohde.Valaistus == 1)
      {
        this.KohdeValaistus = 'Kyllä'
        this.KohdeValaistusInfo = this.kohde.ValaistusInfo;
      }
      if (this.kohde.Kommentointi == 1)
      {
        this.KohdeKommentointi = 'Kyllä'
      }
      if (this.kohde.AutomaattinenTilamuutos == 1)
      {
        this.KohdeAutoTila = 'Kyllä'
        this.kohtalainentunnit = [this.kohde.Tilamuutokset[0].TilamuutosStart,this.kohde.Tilamuutokset[0 ].TilamuutosStop];
      }
      

    })
    .catch(e => {
      console.log(e);
    });
  }
};
</script>

<style>
.keskitys {
    display: flex;
    align-items: center;
    justify-content: center;
}
table 
{
    table-layout:fixed;
    width:100%;
}
th
{
 overflow: hidden;
 text-overflow: ellipsis;
}
</style>