<template>
<div class="columns is-multiline is-mobile">
    <div class="column is-one-quarter is-narrow">
    <p class="has-text-weight-semibold">Tila</p>
  </div>
  <div class="column">
<b-field grouped group-multiline>
<b-field>
    <b-switch type="is-success" size="is-medium" native-value="Hyvä" :left-label='true' v-model="valitutTilat">Hyvä 😎</b-switch>
</b-field>
<b-field>
    <b-switch type="is-warning" size="is-medium" native-value="Kohtalainen" :left-label='true' v-model="valitutTilat">Kohtalainen 😐</b-switch>
</b-field>
<b-field>
    <b-switch type="is-danger" size="is-medium" native-value="Huono" :left-label='true' v-model="valitutTilat">Huono 😕</b-switch>
</b-field>
<b-field>
    <b-switch type="is-dark" size="is-medium" native-value="Suljettu" :left-label='true' v-model="valitutTilat">Suljettu 👎</b-switch>
</b-field>
</b-field>
  </div>
</div>
</template>

<script>
export default {
  computed: {
    valitutTilat: {
      /* By default get() is used */
      get() {
        return this.$store.state.valitutTilat
      },
      /* We add a setter */
      set(valitutTilat) {
        this.$store.commit('AsetaTilat', valitutTilat)
      }
    }
  }
}
</script>

<style>

</style>