<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card has-text-left">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ this.$attrs.laite.Nimi }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <div class="modal-content"></div>
      <section class="modal-card-body">
        <div v-if="this.$attrs.laite.OnkoLiikkeessa">
          <strong>On liikkeessä ✅</strong>
        </div>
        <div v-if="!this.$attrs.laite.OnkoLiikkeessa">
          <strong>Ei ole liikkeessä ❌</strong>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" aria-label="close" @click="$emit('close')">
          Sulje
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "KarttaLaiteIkkuna",
};
</script>

<style></style>
