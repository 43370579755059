<template>
  <section>
    <div class="columns">

    <div class="column is-4">

    <div class="box has-background-light">
      <b-button
        label="Kommentoi"
        type="is-primary"
        size="is-medium"
        @click="isCardModalActive = true"
      />
    </div>

    </div>

    <div class="column">

    <div class="box has-background-light has-text-left">
      <div v-if="kommentit">
      <div v-for="kommentti in pagiKommentit" :key="kommentti.KommenttiID">
        <article class="message">
          <div class="message-header">
            <p>Kommentti</p>
            <p>
              <span class="tag is-info">Nimimerkki: {{ kommentti.Nimimerkki }}</span> - 
                <span class="tag is-success">Aika: {{ new Date(kommentti.Aikaleima).toLocaleDateString() }}
                {{ new Date(kommentti.Aikaleima).toLocaleTimeString() }}</span>
            </p>
          </div>
          <div class="message-body">
            {{ kommentti.Viesti }}
          </div>
        </article>
      </div>
      </div>
    <b-pagination
    :total="total"
    :current.sync="current"
    :per-page="perPage"
    >
    </b-pagination>
    </div>
    </div>

    </div>
    <b-modal
      v-model="isCardModalActive"
      animation="zoom-out"
      :width="640"
      scroll="keep"
    >
      <div class="card">
        <header class="modal-card-head">
          <p class="modal-card-title">Lisää kommentti</p>
        </header>

        <div class="card-content">
          <b-field label="Nimimerkki">
            <b-input v-model="nimimerkki"></b-input>
          </b-field>
          <b-field label="Viesti">
            <b-input v-model="viesti" maxlength="200" type="textarea"></b-input>
          </b-field>
          <b-rate v-model="arvio" custom-text="Arvio kohteesta (ei pakollinen)" class="keskitys"></b-rate>
          <b-button type="is-success" @click="lisaaKommentti"
            >Lisää kommentti</b-button
          >
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import KohdeDataService from "@/services/kohde.service.js";

export default {
  data() {
    return {
      loading: false,
      nimimerkki: "",
      viesti: "",
      arvio: "",
      kommentit: "",
      data: {},
      isCardModalActive: false,
      current: 1,
      perPage: 5,
    };
  },
  methods: {

    lisaaKommentti() {
      var data = {
        KohdeID: this.$route.params.id,
        Nimimerkki: this.nimimerkki,
        Viesti: this.viesti,
        ArvosteluTahti: this.arvio,
      };

      KohdeDataService.uusiKommentti(data)
        .then((response) => {
          console.log(response.data);
          this.isCardModalActive = false;
          this.haeKohteenTiedot();
        })
        .catch((e) => {
          console.log(e);
        });
    },
      haeKohteenTiedot() {
       KohdeDataService.kohteenKommentointi(this.$route.params.id)
        .then(response => {
          console.log(response.data);
          this.kommentit = response.data.Kommentit;
        })
        .catch(e => {
          console.log(e);
        });
    },
  },

  mounted() {
    KohdeDataService.kohteenKommentointi(this.$route.params.id)
      .then((response) => {
        console.log(response.data);
        this.kommentit = response.data.Kommentit;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  computed: {
      total() {
        return this.kommentit.length
      },
      pagiKommentit() {
        let page_number = this.current-1

        return this.kommentit.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
        
      }
  }
};
</script>

<style>
.keskitys {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>