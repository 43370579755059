<template>
  <section>
    <!-- <div id="sijainti" v-cloak>
  
  <div v-if="errorStr" class="has-text-white">
    Pahoittelut, mutta sijainnin hakemisessa esiintyi ongelmia<br><br>
  </div>
  
  <div v-if="gettingLocation" class="has-text-white">
    <i>Haetaan sijaintia...</i><br><br>
  </div>
  
  <div v-if="location" class="has-text-white">
    Sijaintisi on {{ location.coords.latitude }}, {{ location.coords.longitude}}<br><br>
  </div>
  
</div> -->
    <b-loading is-full-page v-model="latausStatus" can-cancel></b-loading>
    <div class="box">
      <b-table
        :data="suodatetutKohteet"
        :narrowed="isNarrowed"
        :striped="isStriped"
        :bordered="isBordered"
        :default-sort="defaultSort"
        :checked-rows.sync="valitutRivit"
        :checkable="onkoKirjautunut()"
        checkbox-position="left"
        paginated
        per-page="10"
        :default-sort-direction="defaultSortDirection"
      >
        <b-table-column
          field="Nimi"
          label="Nimi"
          sortable
          centered
          :searchable="onkoPaakayttaja()"
        >
          <template v-slot="props">
            <router-link
              :to="'/kohde/' + props.row.KohdeID"
              custom
              v-slot="{ href, navigate }"
            >
              <a
                :href="href"
                @click="navigate"
                v-if="props.row.OmaYllapito == 1"
              >
                {{ props.row.Nimi }} 🏛️
              </a>
            </router-link>

            <router-link
              :to="'/kohde/' + props.row.KohdeID"
              custom
              v-slot="{ href, navigate }"
            >
              <a
                :href="href"
                @click="navigate"
                v-if="props.row.OmaYllapito != 1"
              >
                {{ props.row.Nimi }}
              </a>
            </router-link>
          </template>
        </b-table-column>

        <b-table-column field="Tyyppi" label="Tyyppi" sortable centered>
          <template v-slot="props">
            <p v-if="props.row.Tyyppi === 'Hiihtolatu'">
              {{ props.row.Tyyppi }} ⛷️
            </p>
            <p v-else-if="props.row.Tyyppi === 'Luistelukenttä'">
              {{ props.row.Tyyppi }} 🏒
            </p>
            <p v-else-if="props.row.Tyyppi === 'Pulkkamäki'">
              {{ props.row.Tyyppi }} 🛷
            </p>
            <p v-else>{{ props.row.Tyyppi }}</p>
          </template>
        </b-table-column>

        <b-table-column
          field="Tila"
          label="Tila"
          centered
          sortable
          :custom-sort="customSort"
        >
          <template v-slot="props">
            <div v-if="props.row.Tila === 'Hyvä'">
              <svg width="100" height="25">
                <rect
                  width="100"
                  height="25"
                  rx="5"
                  style="fill: rgb(72, 199, 142)"
                >
                  <title>Tila: Hyvä</title>
                </rect>
                <text
                  x="50%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  transform="translate(-10px, 0)"
                  style="
                    font-size: 14px;
                    fill: white;
                    text-shadow: rgb(63, 63, 63) 1px 0 5px;
                  "
                >
                  Hyvä
                </text>
              </svg>
            </div>

            <div v-else-if="props.row.Tila === 'Kohtalainen'">
              <svg width="100" height="25">
                <rect
                  width="100"
                  height="25"
                  rx="5"
                  style="fill: rgb(255, 224, 138)"
                >
                  <title>Tila: Kohtalainen</title>
                </rect>
                <text
                  x="50%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  transform="translate(-10px, 0)"
                  style="
                    font-size: 14px;
                    fill: white;
                    text-shadow: rgb(63, 63, 63) 1px 0 5px;
                  "
                >
                  Kohtalainen
                </text>
              </svg>
            </div>

            <div v-else-if="props.row.Tila === 'Huono'">
              <svg width="100" height="25">
                <rect
                  width="100"
                  height="25"
                  rx="5"
                  style="fill: rgb(241, 70, 104)"
                >
                  <title>Tila: Huono</title>
                </rect>
                <text
                  x="50%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  transform="translate(-10px, 0)"
                  style="
                    font-size: 14px;
                    fill: white;
                    text-shadow: rgb(63, 63, 63) 1px 0 5px;
                  "
                >
                  Huono
                </text>
              </svg>
            </div>

            <div v-else-if="props.row.Tila === 'Suljettu'">
              <svg width="100" height="25">
                <rect
                  width="100"
                  height="25"
                  rx="5"
                  style="fill: rgb(41, 41, 41)"
                >
                  <title>Tila: Suljettu</title>
                </rect>
                <text
                  x="50%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  transform="translate(-10px, 0)"
                  style="
                    font-size: 14px;
                    fill: white;
                    text-shadow: rgb(63, 63, 63) 1px 0 5px;
                  "
                >
                  Suljettu
                </text>
              </svg>
            </div>
          </template>
        </b-table-column>
        <b-table-column
          field="PoistettuKaytosta"
          label="Poistettu käytöstä?"
          centered
          sortable
          v-slot="props"
          v-if="currentUser"
        >
          <span class="tag is-success" v-if="props.row.PoistettuKaytosta == 0"
            >Ei</span
          >
          <span class="tag is-danger" v-if="props.row.PoistettuKaytosta == 1"
            >Kyllä</span
          >
        </b-table-column>
        <b-table-column
          field="Etäisyys"
          label="Etäisyys"
          v-if="location"
          centered
          numeric
        >
          <template v-slot="props">
            <p>
              {{
                laskeEtaisyys(
                  props.row.KoordinaattiLAT,
                  props.row.KoordinaattiLON
                )
              }}
              km
            </p>
          </template>
        </b-table-column>

        <b-table-column
          field="Huollettu"
          label="Huollettu"
          sortable
          centered
          v-slot="props"
        >
          <div v-if="props.row.Huollettu !== null">
            <span :class="HuoltoClass(props.row.Huollettu)">
              {{ new Date(props.row.Huollettu).toLocaleDateString() }}
              {{ new Date(props.row.Huollettu).toLocaleTimeString() }}
            </span>
          </div>
          <div v-else-if="props.row.Huollettu == null">
            <span class="tag is-warning"> Huoltoa ei ole suoritettu </span>
          </div>
        </b-table-column>

        <b-table-column
          field="toiminnot"
          label="Toiminnot"
          centered
          v-slot="props"
          v-if="currentUser"
        >
          <div class="buttons is-centered">
            <b-button
              v-if="
                onkoHuoltoOikeus(props.row.Oikeudet) ||
                onkoPaakayttaja() ||
                onkoHuoltaja()
              "
              focused
              tag="router-link"
              type="is-small is-link"
              :to="'/kohde/huolto/' + props.row.KohdeID"
              label="Huolto"
              icon-left="tools"
            ></b-button>
            <b-button
              v-if="
                onkoMuokkausOikeus(props.row.Oikeudet) ||
                onkoPaakayttaja() ||
                onkoMuokkaaja()
              "
              focused
              tag="router-link"
              type="is-small is-info"
              :to="'/kohde/muokkaa/' + props.row.KohdeID"
              label="Muokkaa"
              icon-left="edit"
            ></b-button>
          </div>
        </b-table-column>
        <template #bottom-left v-if="currentUser">
          <b>Valittuja rivejä</b>: {{ valitutRivit.length }}
        </template>
      </b-table>
    </div>
    <b-collapse
      class="card"
      animation="slide"
      v-if="currentUser && valitutRivit.length > 0"
      aria-id="contentIdForA11y3"
      :open="false"
    >
      <div class="card-header" role="button" slot="trigger" slot-scope="props">
        <p class="card-header-title">Toiminnot</p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'angle-down' : 'angle-up'"> </b-icon>
        </a>
      </div>
      <div class="card-content">
        <div class="content has-background-white keskitys" style="height: 100%">
          <div class="buttons">
            <b-button
              type="is-success is-large"
              @click="merkitseUseanTila('Hyvä')"
              label="Hyvä"
              icon-left="thumbs-up"
            ></b-button>
            <b-button
              type="is-warning is-large"
              @click="merkitseUseanTila('Kohtalainen')"
              label="Kohtalainen"
              icon-left="minus"
            ></b-button>
            <b-button
              type="is-danger is-large"
              @click="merkitseUseanTila('Huono')"
              label="Huono"
              icon-left="thumbs-down"
            ></b-button>
            <b-button
              type="is-dark is-large"
              @click="merkitseUseanTila('Suljettu')"
              label="Sulje kohde"
              icon-left="times"
            ></b-button>
          </div>
        </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import { mapState } from "vuex";
import haversine from "haversine-distance";
import KohdeDataService from "@/services/kohde.service.js";

export default {
  name: "Kohdeluettelo",
  data() {
    return {
      isStriped: true,
      isNarrowed: false,
      isBordered: false,
      defaultSortDirection: "desc",
      defaultSort: "Tila",
      location: null,
      gettingLocation: false,
      errorStr: null,
      valitutRivit: [],
      kohteetyllapito: {},
    };
  },
  mounted() {
    if (this.currentUser) {
      console.log("Haetaan kohteita käyttäjän oikeuksin");
      this.$store.dispatch("haeKohteet", { kayttajaid: this.currentUser.id });
    } else {
      this.$store.dispatch("haeKohteetJulkinen");
    }
  },
  created() {
    if (!("geolocation" in navigator)) {
      this.errorStr = "Sijainti ei ole saatavilla.";
      return;
    }

    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.location = pos;
      },
      (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      }
    );
  },
  methods: {
    customSort(a, b, isAsc) {
      const gradeMap = {
        Hyvä: 4,
        Kohtalainen: 3,
        Huono: 2,
        Suljettu: 1,
      };
      console.log(isAsc);

      if (isAsc) {
        return gradeMap[a.Tila] - gradeMap[b.Tila];
      }

      if (!isAsc) {
        return gradeMap[b.Tila] - gradeMap[a.Tila];
      }
    },

    HuoltoClass(huollettu) {
      let tanaan = new Date();
      let tanaantimestamp = tanaan.getTime();
      let huollettuprop = new Date(huollettu);
      let huollettutimestamp = huollettuprop.getTime();

      let erotus = tanaantimestamp - huollettutimestamp;

      let erotuspaivia = erotus / (1000 * 60 * 60 * 24);

      //console.log(erotuspaivia)

      return erotuspaivia < 30
        ? "tag is-success"
        : erotuspaivia < 60
        ? "tag is-warning"
        : "tag is-danger";
    },
    // haeKohteetYllapito() {
    //   KohdeDataService.haeKohteetYllapito(this.currentUser.id)
    //     .then(response => {
    //       this.kohteetyllapito = response.data;
    //       console.log(response.data);
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });

    // },

    onkoKirjautunut() {
      if (this.currentUser) {
        return true;
      }
    },

    onkoHuoltoOikeus(oikeudet) {
      for (var i = 0; i < oikeudet.length; i++) {
        //console.log("Tarkistetaan kohteelle: " + oikeudet[i].KohdeID + " huolto-oikeus")
        //console.log(oikeudet[i])
        if (oikeudet[i].VoiHuoltaa == 1) {
          //console.log("Huolto-oikeus löytyi")
          return true;
        }
      }
    },

    onkoMuokkausOikeus(oikeudet) {
      for (var i = 0; i < oikeudet.length; i++) {
        //console.log("Tarkistetaan kohteelle: " + oikeudet[i].KohdeID + " huolto-oikeus")
        //console.log(oikeudet[i])
        if (oikeudet[i].VoiMuokata == 1) {
          //console.log("Muokkaus-oikeus löytyi kohteelle: " + oikeudet[i].KohdeID)
          return true;
        }
      }
    },

    onkoMuokkaaja() {
      if (this.currentUser) {
        for (var i = 0; i < this.currentUser.roolit.length; i++) {
          //console.log(this.currentUser.roolit[i].Rooli)
          if (this.currentUser.roolit[i].Rooli.RooliNimi == "Muokkaaja") {
            //console.log("Muokkaajan oikeudet käytössä")
            return true;
          }
        }
      }
    },

    onkoHuoltaja() {
      if (this.currentUser) {
        for (var i = 0; i < this.currentUser.roolit.length; i++) {
          //console.log(this.currentUser.roolit[i].Rooli)
          if (this.currentUser.roolit[i].Rooli.RooliNimi == "Huoltaja") {
            //console.log("Muokkaajan oikeudet käytössä")
            return true;
          }
        }
      }
    },

    onkoPaakayttaja() {
      if (this.currentUser) {
        for (var i = 0; i < this.currentUser.roolit.length; i++) {
          //console.log(this.currentUser.roolit[i].Rooli)
          if (this.currentUser.roolit[i].Rooli.RooliNimi == "Pääkäyttäjä") {
            //console.log("Pääkäyttäjän oikeudet käytössä")
            return true;
          }
        }
      }
    },

    laskeEtaisyys(kohdelat, kohdelon) {
      if (kohdelat === undefined || kohdelat === null) {
        return "Ei tiedossa";
      }

      var etaisyys = "0";
      const kohde = { latitude: kohdelat, longitude: kohdelon };
      const kayttaja = {
        latitude: this.location.coords.latitude,
        longitude: this.location.coords.longitude,
      };
      etaisyys = haversine(kohde, kayttaja);
      etaisyys = etaisyys / 1000;
      etaisyys = Math.round(etaisyys * 100) / 100;
      return etaisyys;
    },
    merkitseUseanTila(tila) {
      var data = {
        TunnusID: this.currentUser.id,
        Kohteet: this.valitutRivit,
        Tila: tila,
      };

      KohdeDataService.useankohteenTilamuutos(data)
        .then((response) => {
          console.log(response.data);
          this.$store.dispatch("haeKohteet");
          this.valitutRivit = "";
          this.$buefy.toast.open({
            message: "Usean kohteen tila muuttaminen onnistui",
            type: "is-success",
          });
        })
        .catch((e) => {
          console.log(e);
          this.valitutRivit = "";
          this.$store.dispatch("haeKohteet");
          this.$buefy.toast.open({
            message: "Usean kohteen tila muuttaminen epäonnistui",
            type: "is-danger",
          });
        });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    ...mapState(["kohteet", "latausStatus", "valitutTilat", "valitutTyypit"]),

    suodatetutKohteet() {
      return this.kohteet.filter(({ Tila, Tyyppi }) => {
        return (
          (this.valitutTyypit.length === 0 ||
            this.valitutTyypit.includes(Tyyppi)) &&
          (this.valitutTilat.length === 0 || this.valitutTilat.includes(Tila))
        );
      });
    },
  },
};
</script>

<style>
.keskitys {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
