<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Päivitä omia tietoja</p>
      <button type="button" class="delete" @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <b-field label="Sähköposti">
        <b-input type="email" v-model="modalSahkoposti" required></b-input>
      </b-field>
      <b-field label="Puhelin">
      <b-input v-model="modalPuhelin" type="text" maxlength="40" pattern="^358*[\s\./0-9]*"></b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot" style="justify-content: center;">
      <button class="button" type="button" @click="$emit('close')">Sulje</button>
      <button class="button is-primary" @click="paivitaTiedot()">Tallenna</button>
    </footer>
  </div>
</template>

<script>
import UserService from "@/services/user.service.js";

export default {
  name: "ProfiiliOmatTiedotModal",
  data() {
    return {
      modalSahkoposti: "",
      modalPuhelin: ""
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
      this.modalSahkoposti = this.currentUser.email;
      this.modalPuhelin = this.currentUser.puhelin;
  },
  methods: {
    paivitaTiedot() {
      var data = {
        TunnusID: this.currentUser.id,
        Sahkoposti: this.modalSahkoposti,
        Puhelin: this.modalPuhelin
      };
      UserService.paivitaOmatTiedot(data)
        .then(response => {
          console.log(response.data);
          this.$buefy.toast.open({
            message: `Omien tietojen päivitys onnistui`,
            type: 'is-success'
          })
          this.$emit('close')
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            message: `Omien tietojen päivitys epäonnistui`,
            type: 'is-success'
          })
          this.$emit('close')
        });
    },
  }
};
</script>