import axios from 'axios';
import authHeader from './auth-header';

const API_URL = asetukset.apipalvelin;

class UserService {
  julkinenSisalto() {
    return axios.get(API_URL + '/all');
  }

  testaaToken() {
    return axios.get(API_URL + '/suojattu', { headers: authHeader() });
  }

  luetteleKayttajat() {
    return axios.get(API_URL + `/kayttaja`, { headers: authHeader() } );
  }

  haeRoolit() {
    return axios.get(API_URL + `/rooli/kaikki`, { headers: authHeader() } );
  }

  lisaaRooli(data) {
    return axios.post(API_URL + '/rooli/lisaa', data, { headers: authHeader() } );
  }

  muokkaaRooli(data) {
    return axios.post(API_URL + '/rooli/muokkaa', data, { headers: authHeader() } );
  }

  lisaaOikeus(data) {
    return axios.post(API_URL + '/oikeus/lisaa', data, { headers: authHeader() } );
  }

  poistaOikeus(oikeusid) {
    return axios.get(API_URL + `/oikeus/poista/${oikeusid}`, { headers: authHeader() } );
  }

  poistaRooli(rooliid) {
    return axios.get(API_URL + `/rooli/poista/${rooliid}`, { headers: authHeader() } );
  }

  haeRooliOikeudet(rooliid) {
    return axios.get(API_URL + `/rooli/oikeus/${rooliid}`, { headers: authHeader() } );
  }

  lisaaRooliKayttaja(id,rooliid) {
    return axios.get(API_URL + `/kayttaja/${id}/rooli/lisaa/${rooliid}`, { headers: authHeader() } );
  }

  poistaRooliKayttaja(id,rooliid) {
    return axios.get(API_URL + `/kayttaja/${id}/rooli/poista/${rooliid}`, { headers: authHeader() } );
  }

  haeKayttajaTiedot(id) {
    return axios.get(API_URL + `/kayttaja/tiedot/${id}`, { headers: authHeader() } );
  }

  disabloiKayttaja(id) {
    return axios.get(API_URL + `/kayttaja/disable/${id}`, { headers: authHeader() } );
  }

  enabloiKayttaja(id) {
    return axios.get(API_URL + `/kayttaja/enable/${id}`, { headers: authHeader() } );
  }

  vaihdaSalasana(data) {
    return axios.post(API_URL + `/kayttaja/paivita/salasana`, data, { headers: authHeader() } );
  }

  paivitaOmatTiedot(data) {
    return axios.post(API_URL + `/kayttaja/paivita/tiedot`, data, { headers: authHeader() } );
  }

  paivitaKayttajaTiedot(data) {
    return axios.post(API_URL + '/kayttaja/paivita/tiedot/yllapito', data, { headers: authHeader() } );
  }

  lisaaKayttaja(data) {
    return axios.post(API_URL + '/luo-tunnus', data, { headers: authHeader() } );
  }

  kirjautumisTokenGenerated(data) {
    return axios.post(API_URL + `/kirjaudu/token/yllapito/uusi`, data, { headers: authHeader() } );
  }

}

export default new UserService();
import asetukset from '@/asetukset.js'